import React from 'react';
import { FiUser } from 'react-icons/fi';
import { FaUser } from 'react-icons/fa';
import { RiUserAddFill } from 'react-icons/ri';
import { IoCheckbox } from 'react-icons/io5';
import { ImCross } from 'react-icons/im';

const License = (props: any) => {
  return (
    <div className="container section-padding">
      <div className="section-title-wrapper">
        {/* {postData?.length > 0 && ( */}
        <div className="section-title">
          The <span className="gradient-text">UnicornUI License</span> : Store
          Licensing
        </div>
        {/* )} */}
      </div>
      <div className="border border-1 rounded-4 container">
        <div className="mt-4 linces-fulldescription">
          The <span className="linces-description">UnicornUI License</span>{' '}
          gives you the right to use an product purchased from the Unicorn-Ui
          Store for personal use or as part of a commercial project, for
          yourself or a third party. However, the product cannot be
          redistributed for free or sold, on its own or in a bundle, where it
          constitutes the core value of the end product.
        </div>
        <div className="mt-3 linces-fulldescription">
          Here is a full list of what you can and can't do when purchasing an
          product in the Unicorn-Ui Store:
        </div>
        <div className="border-bottom mt-4 mb-5"></div>
        <div className="row">
          <div className="col-md-4">
            <div>
              <FiUser
                className="d-inline-block"
                style={{ height: '24px', width: '24px' }}
                color="#9D9D9D"
              />
            </div>
            <div className="text-theme mt-2 personal-license">
              Free Personal License
            </div>
            <div className="text-theme license-text mb-2">Unimited license</div>
            <div className="long-text w-75">
              Each download allows the buyer to use the product for personal use
              only. You'll need to purchase a commercial license if you wish to
              make the modified version public.
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <FaUser
                className="d-inline-block"
                style={{ height: '24px', width: '24px' }}
                color="#5347EB"
              />
            </div>
            <div className="text-theme mt-2 Commercial-license">
              Commercial License
            </div>
            <div className="text-theme license-text mb-2">Commercial use</div>
            <div className="long-text w-75">
              Each purchase allows the buyer to use the product, for one project
              for commercial use. Please note that end users cannot be charged
              for this product and you can use it in only one project.
            </div>
          </div>
          <div className="col-md-4">
            <div>
              <RiUserAddFill
                className="d-inline-block gradient-text"
                style={{ height: '24px', width: '24px' }}
                color="#6345ed"
              />
            </div>
            <div className="text-theme mt-2 Extended-license gradient-text">
              Extended Commercial License
            </div>
            <div className="text-theme license-text mb-2">
              Can be included in a product for sale
            </div>
            <div className="long-text w-75">
              Each purchase allows the buyer to use the product for an unlimited
              amount of projects for commercial use. This use is limited within
              the same company.
            </div>
          </div>
        </div>
        <div className="border-bottom mt-5 mb-5"></div>
        <div className="mt-3">
          <div>
            <div className="d-flex align-items-center">
              <div>
                <IoCheckbox
                  style={{ height: '24px', width: '24px' }}
                  color="#00C106"
                />
              </div>
              <div className="ms-2 unlimited-time"> Unlimited time</div>
            </div>
            <div className="word-space">
              The Unicorn-Ui License is not limited in time.
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <div>
                <IoCheckbox
                  style={{ height: '24px', width: '24px' }}
                  color="#00C106"
                />
              </div>
              <div className="ms-2 unlimited-time"> Free updates</div>
            </div>
            <div className="word-space">
              Should the creator update the product, you will receive the
              updated product for free.
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <div>
                <IoCheckbox
                  style={{ height: '24px', width: '24px' }}
                  color="#00C106"
                />
              </div>
              <div className="ms-2 unlimited-time"> Use in an end-product</div>
            </div>
            <div className="word-space">
              The products may be used as part of an end-project offered for
              sale or for free. The number of projects it may be used in depends
              on the License type.
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <div>
                <ImCross
                  style={{ height: '20px', width: '20px' }}
                  color="red"
                />
              </div>
              <div className="ms-2 unlimited-time"> No-reselling</div>
            </div>
            <div className="word-space">
              <div>
                The product cannot be used in a product offered for sale or for
                free, where the product contributes to the core value of the
                product being sold.
              </div>
              The product may not be used for re-selling, sub-licensing, or
              sharing purposes, and cannot otherwise be redistributed on its own
              (even for free). It can be included in a commercial product as
              long as you purchase the extended commercial license.
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <div>
                <ImCross
                  style={{ height: '20px', width: '20px' }}
                  color="red"
                />
              </div>
              <div className="ms-2 unlimited-time"> Site support only</div>
            </div>
            <div className="word-space">
              We will provide assistance to help you find and download a product
              but we will not provide support to use the product.
            </div>
          </div>
          <div className="mt-3">
            <div className="d-flex align-items-center">
              <div>
                <ImCross
                  style={{ height: '20px', width: '20px' }}
                  color="red"
                />
              </div>
              <div className="ms-2 unlimited-time"> No exclusivity</div>
            </div>
            <div className="word-space">
              An Unicorn-Ui License is not exclusive and other buyers may
              purchase the same product.
            </div>
          </div>
        </div>
        <div className="mt-5 table-main-text">
          <div>
            Here is what you can and can't do when purchasing an product in the
            Unicorn-Ui Store
          </div>
          <div className="row border rounded-4 container mt-4 mb-4">
            <div className="col-md-6 mt-3 px-5">
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <IoCheckbox
                    style={{ height: '24px', width: '24px' }}
                    color="#00C106"
                  />
                </div>
                <div className="ms-2 unlimited-time text-color">
                  {' '}
                  You can...
                </div>
              </div>
              <div className="border-bottom mt-3 mb-3"></div>
              <div>
                <ul style={{ listStyle: 'disc' }}>
                  <li className="list">
                    You can use the product to build a project that is personal
                    or for a client, such as a theme or an icon for a website or
                    an app.
                  </li>
                  <li className="list">
                    You can ask for assistance should you experience
                    difficulties downloading or opening the file.
                  </li>
                  <li className="list">
                    You or other buyers can purchase the same product multiple
                    times.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6 mt-3 px-5">
              <div className="d-flex align-items-center justify-content-center">
                <div>
                  <ImCross
                    style={{ height: '20px', width: '20px' }}
                    color="red"
                  />
                </div>
                <div className="ms-2 unlimited-time text-danger">
                  {' '}
                  You cannot...
                </div>
              </div>
              <div className="border-bottom mt-3 mb-3"></div>
              <div>
                <ul style={{ listStyle: 'disc' }}>
                  <li className="list">
                    You can't use an icon, for example, in a logo on its own or
                    where it constitutes the core of the logo.
                  </li>
                  <li className="list">
                    You can't give away the product on your blog or website.
                  </li>
                  <li className="list">
                    You can't ask for extensive support from the product creator
                    or Unicorn-Ui for you to use the product or modify it.
                  </li>
                  <li className="list mb-4">
                    You can't claim the product for exclusive personal or
                    commercial use.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 ">
          <div className="table-main-text">
            If you are not 100% sure about what is or isn’t allowed under the
            UnicornUI License:
          </div>
          <div className="row  mt-3 mb-5">
            <div className="col-md-4">
              <div className="table-main-text">FAQ</div>
              <div className="text-normal">
                Do you want to learn more about the Unicorn-Ui Store?
              </div>
              <div className="gradient-text border-gradient cursor-pointer">
                {' '}
                Read our FAQ{' '}
              </div>
            </div>
            <div className="col-md-4">
              <div className="table-main-text">Support</div>
              <div className="text-normal">
                Do you wish to sell products and have a specific questions?
              </div>
              <div className="gradient-text border-gradient cursor-pointer">
                {' '}
                Send us an email{' '}
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default License;
