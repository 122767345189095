import React from 'react';

const Privacy = (props: any) => {
  return (
    <div className="container section-padding">
      <div className="section-title-wrapper">
        {/* {postData?.length > 0 && ( */}
        <div className="section-title">
          <span className="gradient-text">Privacy</span> Policy
        </div>
        {/* )} */}
      </div>
      <div className="border border-1 rounded-4 container">
        <div className="mt-3">
          <div className=" fw_800">
            Welcome to Privacy Policy of{' '}
            <span className="gradient-text"> Unicorn UI </span>
          </div>
          <div>
            This page informs you of our policies regarding the collection, use
            and disclosure of personal data when you use our product or services
            and the choices you have associated with that data.
          </div>
          <div>
            We use your data to provide and improve the Service. By using the
            Service, you agree to the collection and use of information in
            accordance with this policy.{' '}
          </div>
        </div>
        <div className="mt-5">
          <h6>Definitions</h6>
          <div>
            Definitions of some terms cound help you understand this policy.
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-3">
            <li>
              <span className="gradient-text fw_800 mt-1">"Personal Data"</span>{' '}
              is the data about a living individual who can be identified from
              those data (or from those and other information either in our
              possession or likely to come into our possession).
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">"Usage Data"</span> is
              bing collected automatically either generated by the use of the
              Service or from the Service infrastructure itself (for example,
              the duration of a page visit).
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">"Cookies"</span> are
              small files stored on your device (computer or mobile device).
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">
                "Data Controller"
              </span>{' '}
              are means natural or legal person who (either alone or jointly or
              in common with other persons) determines the purposes for which
              and the manner in which any personal information are, or are to
              be, processed.For the purpose of this Privacy Policy, we are a
              Data Controller of your Personal Data.
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">
                "Data Processors (or Service Providers)"
              </span>{' '}
              means any natural or legal person who processes the data on behalf
              of the Data Controller.We may use the services of various Service
              Providers in order to process your data more effectively.
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">"Services"</span>{' '}
              means Unicorn Ui's product including content, and analytics which
              would used by the users.
            </li>
            <li>
              <span className="gradient-text fw_800 mt-1">
                "Website Visitor"
              </span>{' '}
              refers to anyone visiting our Website.
            </li>
          </ul>
        </div>
        <div className="mt-5">
          <h6>Collection of data we use</h6>
          <div>
            We collect several different types of data and information for
            various purposes to provide and improve our Service to you.
          </div>
          <div className="mt-1 data-type">Types of Data Collected</div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Personal Data
            </div>
            <div className="mt-1">
              While using our Service, we may ask you to provide us with certain
              personally identifiable information that can be used to contact or
              identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Email address
            </div>
            <div className="mt-1">First name and last name of users</div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Cookies and Usage Data
            </div>
            <div className="mt-1">
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link
              or the instructions provided in any email we send.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Usage Data
            </div>
            <div className="mt-1">
              We may also collect information on how the Service is accessed and
              used (“Usage Data”). This Usage Data may include information such
              as your computer’s Internet Protocol address (e.g. IP address),
              browser type, browser version, the pages of our Service that you
              visit, the time and date of your visit, the time spent on those
              pages, unique device identifiers and other diagnostic data.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Tracking & Cookies Data
            </div>
            <div className="mt-1">
              We use cookies and similar tracking technologies to track the
              activity on our Service and we hold certain information.
            </div>
            <div className="mt-1">
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyse our Service.
            </div>
            <div className="mt-1">
              You can instruct your browser to refuse all cookies or to indicate
              when a cookie is being sent. However, if you do not accept
              cookies, you may not be able to use some portions of our Service.
            </div>
          </div>
          <div className="mt-3">
            <div className="gradient-text fw_800 border-gradient">
              Examples of Cookies we use:
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Session Cookies :
              </span>{' '}
              We use Session Cookies to operate our Service.
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Preference Cookies :
              </span>{' '}
              We use Preference Cookies to remember your preferences and various
              settings.
            </div>
            <div className="mt-1">
              <span className="fw_800 border-bottom border-dark">
                Security Cookies :
              </span>{' '}
              We use Security Cookies for security purposes.
            </div>
          </div>
        </div>
        <div className="mt-5">
          <h6>Use of Data</h6>
          <div className="mt-1 fw_800">
            We uses the collected data for various purposes:
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li>To provide and maintain our Service</li>
            <li>To notify you about changes to our Service</li>
            <li>
              To allow you to participate in interactive features of our Service
              when you choose to do so
            </li>
            <li>To provide customer support</li>
            <li>
              To gather analysis or valuable information so that we can improve
              our Service
            </li>
            <li>To monitor the usage of our Service</li>
            <li>To detect, prevent and address technical issues</li>
            <li>
              To provide you with news, special offers and general information
              about other goods, services and events which we offer that are
              similar to those that you have already purchased or enquired about
              unless you have opted not to receive such information
            </li>
          </ul>
        </div>
        <div className="mt-5">
          <h6>
            Legal Basis for Processing Personal Data under the General Data
            Protection Regulation (GDPR)
          </h6>
          <div className="mt-1">
            If you are from the European Economic Area (EEA), Unicorn UI legal
            basis for collecting and using the personal information described in
            this Privacy Policy depends on the Personal Data we collect and the
            specific context in which we collect it.
          </div>
          <div className="mt-1 fw_800">
            Unicorn UI may process your Personal Data because:
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li>We need to perform a contract with you</li>
            <li>You have given us permission to do so</li>
            <li>
              The processing is in our legitimate interests and it is not
              overridden by your rights
            </li>
            <li>To comply with the law</li>
          </ul>
        </div>
        <div className="mt-5">
          <h6>Third parties data</h6>
          <div className="mt-1">
            We do not sell your Personal Data to anyone. We may share your
            Personal Data with our third party Service Providers, who help us
            provide and support our Services and products, such as credit card
            processing services, order fulfilment, analytics, event or campaign
            management, website management, information technology and related
            infrastructure provision, customer service, e-mail delivery,
            auditing, and other similar services. In this case, we require by
            contract from our services providers to use your Personal Data only
            for the purpose of providing services to us and subject to terms
            consistent with this policy, along with all applicable regulations
            regarding personal data protection.
          </div>
          <div className="mt-1">
            We ensure that the data we collect from the users will be secure and
            don't be shared to untrusted parties. These third parties have
            access to your Personal Data only to perform these tasks on our
            behalf and are obligated not to disclose or use it for any other
            purpose.
          </div>
        </div>
        <div className="mt-5">
          <h6>Legal Requirements</h6>
          <div className="mt-1 fw_800">
            Unicorn UI may disclose your Personal Data in the good faith belief
            that such action is necessary to:
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li>To comply with a legal obligation</li>
            <li>To protect and defend the rights or property of Unicorn UI</li>
            <li>
              To prevent or investigate possible wrongdoing in connection with
              the Service
            </li>
            <li>
              To protect the personal safety of users of the Service or the
              public
            </li>
            <li>To protect against legal liability</li>
          </ul>
          <div className="mt-1 fw_800">
            Your Data Protection Rights under the General Data Protection
            Regulation (GDPR)
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li className="mt-1">
              If you are a resident of the European Economic Area (EEA), you
              have certain data protection rights. The aim of Unicorn UI is to
              take reasonable steps to allow you to correct, amend, delete or
              limit the use of your Personal Data.
            </li>
            <li className="mt-1">
              If you wish to be informed about what Personal Data we hold about
              you and if you want it to be removed from our systems, please
              contact us.
            </li>
            <li className="mt-1">
              In certain circumstances, you have the following data protection
              rights:
            </li>
            <li className="mt-1">
              The right to access, update or delete the information we have on
              you. Whenever made possible, you can access, update or request
              deletion of your Personal Data directly within your account
              settings section. If you are unable to perform these actions
              yourself, please contact us to assist you.
            </li>
            <li className="mt-1">
              The right of rectification. You have the right to have your
              information rectified if that information is inaccurate or
              incomplete.
            </li>
            <li className="mt-1">
              The right to object. You have the right to object to our
              processing of your Personal Data.
            </li>
            <li className="mt-1">
              The right of restriction. You have the right to request that we
              restrict the processing of your personal information.
            </li>
            <li className="mt-1">
              The right to data portability. You have the right to be provided
              with a copy of the information we have on you in a structured,
              machine-readable and commonly used format.
            </li>
            <li className="mt-1">
              The right to withdraw consent. You also have the right to withdraw
              your consent at any time where we relied on your consent to
              process your personal information.
            </li>
            <li className="mt-1">
              Please note that we may ask you to verify your identity before
              responding to such requests.
            </li>
            <li className="mt-1">
              You have the right to complain to a Data Protection Authority
              about our collection and use of your Personal Data. For more
              information, please contact your local data protection authority
              in the European Economic Area (EEA).
            </li>
          </ul>
        </div>
        <div className="mt-5">
          <h6>Analytics</h6>
          <div className="mt-1">
            We may use third-party Service Providers to monitor and analyse the
            use of our Service.
          </div>
          <div className="mt-1">
            <span className="gradient-text border-gradient">
              {' '}
              Google Analytics:{' '}
            </span>{' '}
            It is a web analytics service offered by Google that tracks and
            reports website traffic. Google uses the data collected to track and
            monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualise
            and personalise the ads of its own advertising network.
          </div>
          <div className="mt-1">
            You can opt-out of having made your activity on the Service
            available to Google Analytics by installing the Google Analytics
            opt-out browser add-on. The add-on prevents the Google Analytics
            JavaScript (ga.js, analytics.js and dc.js) from sharing information
            with Google Analytics about visits activity.
          </div>
        </div>
        <div className="mt-5">
          <h6>Policy for Payments</h6>
          <div className="mt-1">
            We may provide paid products and/or services within the Service. In
            that case, we use third-party services for payment processing (e.g.
            payment processors).
          </div>
          <div className="mt-1">
            We will not store or collect your payment card details. That
            information is provided directly to our third-party payment
            processors whose use of your personal information is governed by
            their Privacy Policy.
          </div>
          <div className="mt-1">
            These payment processors adhere to the standards set by PCI-DSS as
            managed by the PCI Security Standards Council, which is a joint
            effort of brands like Visa, MasterCard, American Express and
            Discover. PCI-DSS requirements help ensure the secure handling of
            payment information.
          </div>
          <div className="mt-1 fw_800">
            Currently the payment processors we work with are:
          </div>
          <ul style={{ listStyle: 'disc' }} className="ms-5">
            <li>
              <span className="gradient-text border-gradient fw_800 mt-1">
                Stripe:-{' '}
              </span>{' '}
              Their Privacy Policy can be viewed at{' '}
              <span
                className="fw_800 cursor-pointer"
                onClick={() =>
                  window.open('https://stripe.com/us/privacy', '_Blank')
                }
              >
                {' '}
                https://stripe.com/us/privacy
              </span>
            </li>
            <li>
              <span className="gradient-text border-gradient fw_800 mt-1">
                PayPal:-{' '}
              </span>{' '}
              BraintreeTheir Privacy Policy can be viewed at{' '}
              <span
                className="fw_800 cursor-pointer"
                onClick={() =>
                  window.open(
                    'https://www.paypal.com/webapps/mpp/ua/privacy-full',
                    '_Blank'
                  )
                }
              >
                https://www.paypal.com/webapps/mpp/ua/privacy-full
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-5">
          <h6>Links to Other Sites</h6>
          <div className="mt-1">
            Our Service may contain links to other sites that are not operated
            by us. If you click a third party link, you will be directed to that
            third party’s site. We strongly advise you to review the Privacy
            Policy of every site you visit.
          </div>
          <div className="mt-1">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </div>
        </div>
        <div className="mt-5">
          <h6>Children’s Privacy</h6>
          <div className="mt-1">
            Our Service does not address anyone under the age of 18
            (“Children”).
          </div>
          <div className="mt-1">
            We do not knowingly collect personally identifiable information from
            anyone under the age of 18. If you are a parent or guardian and you
            are aware that your Child has provided us with Personal Data, please
            contact us. If we become aware that we have collected Personal Data
            from children without verification of parental consent, we take
            steps to remove that information from our servers.
          </div>
        </div>
        <div className="mt-5">
          <h6>Future changes of the Privacy policy</h6>
          <div className="mt-1">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page.
          </div>
          <div className="mt-1">
            We will let you know via email and/or a prominent notice on our
            Service, prior to the change becoming effective and update the
            “effective date” at the top of this Privacy Policy.
          </div>
          <div className="mt-1">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </div>
        </div>
        <div className="mt-5 mb-5">
          <h6>Contact Us</h6>
          <div className="mt-1">
            If you have any questions about this Privacy Policy, please contact
            us:
          </div>
          <div className="mt-1 fw_800">
            By email: unicornuiofficial@gmail.com
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
