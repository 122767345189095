import React, { useEffect } from 'react';
import HeroBanner from './HeroBanner';
import FeaturedItems from './FeaturedItems';
import BrowseCategories from './BrowseCategories';
import NewestItems from './NewestItems';
import LatestNews from './LatestNews';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAuth, profileUpdate } from 'src/redux/reducer/profileUpdateSlice';
import HtmlTwoSlider from './FeaturedSeller';
import Creative from './Creative';
import { useMutation } from 'react-query';
import { ApiGet } from 'src/helpers/API/ApiData';
import UseNav from 'src/Hooks/Header/UseNav';
import Auth from 'src/helpers/Auth';
// import { profileUpdate } from 'src/redux/reducer/profileUpdateSlice';


const HomePageContainer = () => {
  const navData = UseNav();
  const dispatch = useDispatch();
  const isAuth = useSelector(getAuth);


  const location: any = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (isAuth) {
      
      location.state?.from && history.push(location.state?.from);
    } else {
      if (history.location.pathname !== '/') {
  
        // dispatch(openModal('1'));
        // dispatch(isOpenModal(true));
      }
    }
  }, [isAuth]);

  const { mutateAsync: profileDataMutate } = useMutation(
    () => ApiGet('/profile'),
    {
      onSuccess: (response: any) => {
          
        let obj = {
          ...response?.data,
          userType: navData?.parsedLoginData?.userType,
          token: navData?.parsedLoginData?.token
        };
        localStorage.setItem('logindata', JSON.stringify(obj));
   
        localStorage.setItem(
          'planexpire',
          JSON.stringify(response?.data?.planexpiry)
        );
        dispatch(profileUpdate(obj));
      }
    }
  );
  useEffect(() => {
    if(isAuth){
      profileDataMutate()

    }
  }, [])

  return (
    <>
      <HeroBanner />
      <FeaturedItems />
      <BrowseCategories />
      <NewestItems />
      {/* <FeaturedSellers /> */}
      <HtmlTwoSlider />
      <LatestNews />
      <Creative />
    </>
  );
};

export default HomePageContainer;
