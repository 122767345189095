import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Creator() {
  return (
    <div>
      <div className="become-creator-bc-box pt-0 justify-content-between section-padding-medium">
        <div className="bc-info d-flex flex-column px-0 w-100" style={{flexBasis:"100%"}}>
          <div className="bc-heading">
            <div className="bc-heading-subtitle" style={{marginBottom:"20px"}}>JOIN OUR COMMUNITY!</div>
            <div className="bc-heading-title"  >
            <h1 style={{fontSize:"30px" , marginBottom:"0px" }}>Congratulations! Your profile is</h1><span style={{fontSize:"30px" }} className="gradient-text" >Verified!</span>
            </div>
          </div>
          <div className="bc-description">
          Congratulations! We are pleased to inform you that you have been verified as a trusted and talented designer on Unicorn UI. Your dedication and hard work have paid off, and we are excited to have you as a valuable member of our community. Your verified status will allow you to access exclusive features and benefits. We look forward to seeing all the amazing designs you will contribute to our platform and wish you continued success in your design career.
          </div>
          <div className="bc-button" style={{marginTop:"-20px"}}>
          <Link  to="/benefits"><span className="gradient-text"  style={{fontWeight:"700" , cursor:"pointer"}}>Learn how to get verified and what are the benefits?</span></Link>
            {/* <a className="btn btn-wide btn-dark" href="#application-form">
              Fill the Form
            </a> */}
          </div>
        </div>
        <div className="bc-image w-100" style={{width:"50%"}}>
          <img src="/Image/become-creator.png" alt="" />
        </div>
      </div>

    </div>
  );
}

export default Creator;
