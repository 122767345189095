import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';
import { Link } from 'react-router-dom';

function Creator() {
  return (
    <div>
      <div className="become-creator-bc-box pt-0 justify-content-between section-padding-medium">
        <div className="bc-info d-flex flex-column px-0 w-100" style={{ flexBasis: "100%" }}>
          <div className="bc-heading">
            <div className="bc-heading-subtitle" style={{ marginBottom: "20px" }}>JOIN OUR COMMUNITY!</div>
            <div className="bc-heading-title"  >
              <h1 style={{ fontSize: "30px", marginBottom: "0px" }}>Oops! You are not eligible to get</h1><span style={{ fontSize: "30px" }} className="gradient-text">Verified!</span>
            </div>
          </div>
          <span style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "0" }} className="gradient-text">Main Criteria</span>
          <div className="bc-description" style={{ fontSize: "16px", fontWeight: "bold" }}>
            You need minimum 5 post in your profile to get verified.
          </div>
          <span style={{ fontSize: "20px", fontWeight: "bold", marginBottom: "0" }} className="gradient-text">Main Benefit</span>
          <div className="bc-description" style={{ fontSize: "16px", fontWeight: "bold" }}>
            You will receive a share of revenue generated from the number of your Premium Resources downloaded
          </div>
          <div className="bc-description" >
            Please note that our verification process is constantly evolving, and we may revisit our decision in the future. In the meantime, we encourage you to continue building your presence on our platform and engaging with our community. Verification is just one aspect of our platform, and there are many other ways to make the most of your experience.
          </div>
          <div className="bc-button" style={{ marginTop: "-20px" }}>
            <Link to="/benefits"><span className="gradient-text" style={{ fontWeight: "700", cursor: "pointer" }}>Learn how to get verified and what are the benefits?</span></Link>
            {/* <a className="btn btn-wide btn-dark" href="#application-form">
              Fill the Form
            </a> */}
          </div>
        </div>
        <div className="bc-image w-100" style={{ width: "50%" }}>
          <img src="/Image/become-creator.png" alt="" />
        </div>
      </div>

    </div>
  );
}

export default Creator;
