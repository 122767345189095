import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import ItemCard from 'src/components/itemCard';
import NoDataFound from 'src/components/noDataFound';
import NoDataPage from 'src/components/noDataFound/No-data';
import {
  isOpenModal,
  openModal,
  userProfile
} from 'src/redux/reducer/profileUpdateSlice';
import { ApiGet, ApiPost, ApiPostNoAuth } from 'src/services/http-service';

const NewestItems = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState<any>([]);
  const [loader, setloader] = useState(false);
  const userProfileData = useSelector(userProfile);

  const witAuthPostBody = {
    limit: 20,
    page: 1,
    status: 'approve',
    search: '',
    software: [],
    categoryId: [],
    subCategoryId: []
  };

  const postBody = {
    limit: 20,
    page: 1,
    search: '',
    status: 'approve',
    software: [],
    categoryId: [],
    subCategoryId: []
  };

  const { mutate: getExploredDataWithAuth } = useMutation(
    () => ApiPost('/post', witAuthPostBody),
    {
      onSuccess: (res: any) => {
        setData(res?.data?.post_data);
        setloader(false);
      }
    }
  );

  const { mutate: getExploredData } = useMutation(
    () => ApiPostNoAuth('user/post', postBody),
    {
      onSuccess: (res: any) => {
        setData(res?.data?.data?.post_data);
        setloader(false);
      }
    }
  );

  const postLike = (productId: any) => {
    if (localStorage.getItem('logindata')) {
      ApiGet(`/post/like_post/${productId}`).then(() => {
        getExploredDataWithAuth();
      });
    } else {
      dispatch(openModal('1'));
      dispatch(isOpenModal(true));
    }
  };

  React.useEffect(() => {
    setloader(true);
    userProfileData ? getExploredDataWithAuth() : getExploredData();
  }, [userProfileData]);

  return (
    <div className="container section-padding">
      <div className="section-title-wrapper">
        <div className="section-title">
          <span className="gradient-text">Newest</span> Items
        </div>
        <div className="all-items-link">
          {/* <Link to="/explore-items">Explore all Artworks</Link> */}
          <button
            className="gradient-text"
            onClick={() => window.open('/explore-items', '_blank')}
          >
            View More
          </button>
        </div>
      </div>
      <div className="featured-box">
        <div className=" grid-4-columns">
          {!loader && data?.length === 0 && (
            <NoDataPage
              title="Result Not Found"
              image="/Image/Icons/NotFound.png"
              desc=""
              status={false}
              btnName="Go to Home"
              path="/"
            />
          )}

          {loader ? (
            <>
              <div className="row">
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]?.map(
                  (val) => {
                    return (
                      <div className="col-lg-3 col-md-4 col-sm-6">
                        <div className="shimmer1">
                          <div className="wrapper">
                            <div className="image-card1 animate mx-auto"></div>
                            <div className="stroke animate titleeee"></div>
                            <div className="d-flex">
                              <div className=" animate circle"></div>
                              <div className=" animate circle ms-2"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </>
          ) : (
            data?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <ItemCard
                    productId={item._id}
                    totalLikes={item.totalLikes}
                    thumbnail={item.thumbnail}
                    subcategory={item?.subcategory}
                    subCategoryId={item?.subCategoryId}
                    category={item?.category}
                    title={item.title}
                    software={item.software}
                    image={item.user[0]?.image ?? undefined}
                    username={item?.user[0]?.username ?? 'Username'}
                    userId={item?.user[0]?._id}
                    isCreator={item?.user[0]?.isCreator}
                    price={item.price}
                    like={item?.like}
                    postLike={postLike}
                  />
                </div>
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export default NewestItems;
