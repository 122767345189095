import { TextField } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Aside from '../../components/aside';
import { Bucket } from 'src/services/http-service';
import { ApiGet, ApiPost } from 'src/helpers/API/ApiData';
import Pagination from '@material-ui/lab/Pagination';
import NoDataFound from 'src/components/noDataFound';
import Loader from 'src/components/loader';
import NoDataPage from 'src/components/noDataFound/No-data';

const SalesStatement = () => {
  const history = useHistory();

  const [state, setstate] = useState(true);
  const [state2, setstate2] = useState(false);
  const [data, setData] = useState<any>([]);
  const [data2, setData2] = useState<any>([]);
  const [totalpage, settotalpage] = useState(0);
  const [currentpage, setcurrentpage] = useState(1);
  const [pagesize, setpagesize] = useState(10);
  const [isPurchase, setIsPurchase] = useState(false);
  const [month, setMonth] = useState(moment().format('M'));
  const [year, setYear] = useState(moment().format('YYYY'));
  const [loading, setloading] = useState(false);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    new Date()
  );
  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap'
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200
    }
  }));
  const getSalesStatement = (
    page: any,
    limit: any,
    isPurchase: any,
    month: any,
    year: any
  ) => {
    setloading(true);
    let body = {
      month: JSON.parse(month),
      year: JSON.parse(year),
      isPurchase,
      page,
      limit
    };

    ApiPost('/getsales', body).then((res: any) => {
      setData(res?.data?.data);
      settotalpage(res?.data?.state?.page_limit);
      setloading(false);
    });
  };

  const handleDateChange = (date: any | null) => {
    if (date.target.value) {
      const month1 = moment(date.target.value).format('M');
      const year1 = moment(date.target.value).format('YYYY');
      
      setMonth(month1);
      setYear(year1);
      setSelectedDate(date.target.value);
      getSalesStatement(currentpage, pagesize, isPurchase, month1, year1);
    } else {
      handleReset();
    }
  };
  const handleReset = () => {
    setMonth(moment().format('M'));
    setYear(moment().format('YYYY'));
    getSalesStatement(
      currentpage,
      pagesize,
      isPurchase,
      moment().format('M'),
      moment().format('YYYY')
    );
  };
  const classes = useStyles();
  const togglePurchase = (bool: any) => {
    setIsPurchase(bool);
    getSalesStatement(currentpage, pagesize, bool, month, year);
  };
  React.useEffect(() => {
    getSalesStatement(currentpage, pagesize, isPurchase, month, year);
  }, [currentpage, pagesize]);
  const handleChange = (e: any, i: any) => {
  
    setcurrentpage(i);
  };
  const handleonchnagespagination = (e: any) => {
    
    setpagesize(e.target.value);
  };

  let Json = [
    {
      createdAt: '11/04/2024',
      post: [
        {
          thumbnail: '',
          title: 'test',
          catrgory: 'demo'
        }
      ],
      statementList: {
        totalDownload: 10,
        price: 100,
        fee: 10,
        tax: 1,
        earnings: 100
      }
    },
    {
      createdAt: '11/04/2024',
      post: [
        {
          thumbnail: '',
          title: 'test',
          catrgory: 'demo'
        }
      ],
      statementList: {
        totalDownload: 10,
        price: 100,
        fee: 10,
        tax: 1,
        earnings: 100
      }
    },
    {
      createdAt: '11/04/2024',
      post: [
        {
          thumbnail: '',
          title: 'test',
          catrgory: 'demo'
        }
      ],
      statementList: {
        totalDownload: 10,
        price: 100,
        fee: 10,
        tax: 1,
        earnings: 100
      }
    },
    {
      createdAt: '11/04/2024',
      post: [
        {
          thumbnail: '',
          title: 'test',
          catrgory: 'demo'
        }
      ],
      statementList: {
        totalDownload: 10,
        price: 100,
        fee: 10,
        tax: 1,
        earnings: 100
      }
    }
  ];

  return (
    <>
      <div className="primary-content-area container content-padding grid-left-sidebar">
        <Aside />
        <div className="main-content-area sales-statement">
          <div className="page-title-section">
            <h2>
              <span className="gradient-text">Sales</span> Statement
            </h2>
          </div>
          <div className="dashboard-wrapper">
            {/* <div className="user-stats-section">
              <div className="stat-item">
                <div className="stat-number">$253.36</div>
                <div className="stat-description">Monthly Earnings</div>
              </div>
              <div className="stat-item">
                <div className="stat-number green">+54.2%</div>
                <div className="stat-description">From Last Month</div>
              </div>
              <div className="stat-item">
                <div className="stat-number red">$17.60</div>
                <div className="stat-description">Montly Taxes and Fees</div>
              </div>
              <div className="stat-item">
                <div className="stat-number">$1,978,356.04</div>
                <div className="stat-description">
                  Total Sales Amount (before fees and taxes)
                </div>
              </div>
            </div> */}
            <ul className="tabs-list mb-5">
              <li
                className={`swiper-slide  w-auto ${!isPurchase && 'active'}`}
                onClick={() => togglePurchase(false)}
              >
                <a>Download sales</a>
              </li>
              <li
                className={`swiper-slide  w-auto ${isPurchase && 'active'}`}
                onClick={() => togglePurchase(true)}
              >
                <a>Purchase sales</a>
              </li>
            </ul>
            <div className="statement-list">
              <div className="filterable-bar">
                <form id="creators-filter-form">
                  <TextField
                    id="date"
                    type="month"
                    placeholder="Select Month"
                    className={`${classes?.textField} textheight`}
                    style={{ height: '43px' }}
                    defaultValue={moment().format('YYYY-MM')}
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={handleDateChange}
                  />
                  {/* <div className="filter-button">
                    <button
                      className="btn btn-normal btn-dark waves-effect waves-button waves-float waves-light"
                      type="button"
                      onClick={handleReset}
                    >
                      Reset
                    </button>
                  </div> */}
                </form>
              </div>
              <div className="statement">
                {loading ? (
                  <Loader />
                ) : data?.length !== 0 ? (
                  isPurchase ? (
                    <table className="content-table">
                      <thead>
                        <tr>
                          <th
                            scope="col"
                            className="heading-label"
                            style={{ width: '100px' }}
                          >
                            DATE
                          </th>
                          <th scope="col" className="heading-label w-50">
                            Item details
                          </th>
                          <th
                            scope="col"
                            className="heading-label"
                            style={{ width: '50px' }}
                          >
                            Type
                          </th>
                          <th
                            scope="col"
                            className="heading-label"
                            style={{ width: '80px' }}
                          >
                            Reference
                          </th>
                          <th
                            scope="col"
                            className="heading-label"
                            style={{ width: '100px' }}
                          >
                            Earnings
                          </th>
                          <th
                            scope="col"
                            className="heading-label"
                            style={{ width: '100px' }}
                          >
                            Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* <tr> */}
                        {data?.map((item: any) => (
                          <tr>
                            <td data-label="Date">
                              <div className="date me-3">
                                {moment(item?.createdAt).format('MMM DD,YYYY')}
                              </div>
                            </td>
                            <td
                              data-label="Item details"
                              className="d-flex align-items-center"
                            >
                              <div className="product-info">
                                <div className="product-thumb">
                                  <NavLink to={`/productdetail/${item?.post[0]?.title}/${item?.post[0]?._id}`}>
                                    <img
                                      // src={Bucket + v?.thumbnail}
                                      height="50px" width="100px"
                                      src={
                                        item?.post[0]?.thumbnail
                                          ? item?.post[0]?.thumbnail.split(
                                            '/'
                                          )[2] == 'lh3.googleusercontent.com'
                                            ? item?.post[0]?.thumbnail
                                            : Bucket + item?.post[0]?.thumbnail
                                          : '/Image/Base.png'
                                      }
                                      alt=""
                                    />
                                  </NavLink>
                                </div>
                                <div className="product-details">
                                  <div
                                    className="product-name text-truncate"
                                    style={{ width: '300px' }}
                                  >
                                    <NavLink to={`/productdetail/${item?.post[0]?.title}/${item?.post[0]?._id}`}>
                                      {item?.post[0]?.title || '-'}
                                    </NavLink>
                                    {/* {item?.post[0]?.title || '-'} */}
                                  </div>
                                  <div className="product-meta">
                                    <div
                                      className="item-category ui-templates cursor-pointer"
                                    onClick={() =>
                                      history.push(
                                        `/explore-items/${item?.post?.subCategory?.length > 0
                                          ? item?.post[0]?.subCategory[0]?.name
                                          : item?.post[0]?.category[0]?.name}`
                                      )
                                    }
                                    >
                                      {item?.post?.subCategory?.length > 0
                                        ? item?.post[0]?.subCategory[0]?.name
                                        : item?.post[0]?.category[0]?.name ||
                                        '-'}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td
                              data-label="totalDownload"
                              className="stat-value pe-3"
                            >
                              {item?.licenseType === 1
                                ? 'Commercial'
                                : item?.licenseType === 2
                                  ? 'Extended'
                                  : '0'}
                            </td>
                            <td data-label="price" className="stat-value pe-3">
                              #
                              {Math?.round(
                                new Date(item?.createdAt).getTime() / 1000
                              )}
                            </td>
                            <td
                              data-label="earnings"
                              className="green stat-value"
                            >
                              $
                              {item?.price !== 0
                                ? item?.price
                                : '0'}
                            </td>
                            <td data-label="earnings" className="stat-value">
                              {item?.isStatus ? 'Credited' : 'Pending'}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    data?.length !== 0 && (
                      <table className="content-table">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="heading-label"
                              style={{ width: '100px' }}
                            >
                              DATE
                            </th>
                            <th scope="col" className="heading-label w-50">
                              Item details
                            </th>
                            {/* <th
                          scope="col"
                          className="heading-label"
                          style={{ width: '50px' }}
                        >
                          Type
                        </th> */}
                            <th
                              scope="col"
                              className="heading-label"
                              style={{ width: '80px' }}
                            >
                              Reference
                            </th>
                            <th
                              scope="col"
                              className="heading-label"
                              style={{ width: '100px' }}
                            >
                              Earnings
                            </th>
                            <th
                              scope="col"
                              className="heading-label"
                              style={{ width: '100px' }}
                            >
                              Status
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* <tr> */}
                          {data?.map((item: any) => (
                            <tr>
                              <td data-label="Date">
                                <div className="date me-3">
                                  {moment(item?.createdAt).format(
                                    'MMM DD,YYYY'
                                  )}
                                </div>
                              </td>
                              <td
                                data-label="Item details"
                                className="d-flex align-items-center"
                              >
                                <div className="product-info">
                                  <div className="product-thumb">
                                    <NavLink to={`/productdetail/${item?.post?.title}/${item?.post?._id}`} href="05-product.html">
                                      <img
                                        // src={Bucket + v?.thumbnail}
                                        height="50px" width="100px"
                                        src={
                                          item?.post?.thumbnail
                                            ? item?.post?.thumbnail.split(
                                              '/'
                                            )[2] ==
                                              'lh3.googleusercontent.com'
                                              ? item?.post?.thumbnail
                                              : Bucket + item?.post?.thumbnail
                                            : '/Image/Base.png'
                                        }
                                        alt=""
                                      />
                                    </NavLink>
                                  </div>
                                  <div className="product-details">
                                    <div
                                      className="product-name text-truncate"
                                      style={{ width: '300px' }}
                                    >
                                      <NavLink to={`/productdetail/${item?.post?.title}/${item?.post?._id}`}>
                                        {item?.post?.title}
                                      </NavLink>
                                      {/* {item?.post?.title || '-'} */}
                                    </div>
                                    <div className="product-meta">
                                      <div
                                        className="item-category ui-templates cursor-pointer"
                                      onClick={() =>
                                        history.push(
                                          `/explore-items/${item?.post?.subCategory?.length > 0
                                            ? item?.post?.subCategory[0]?.name
                                            : item?.post?.category[0]?.name}`
                                        )
                                      }
                                      >
                                        {item?.post?.subCategory?.length > 0
                                          ? item?.post?.subCategory[0]?.name
                                          : item?.post?.category[0]?.name ||
                                          '-'}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                              {/* <td
                            data-label="totalDownload"
                            className="stat-value pe-3"
                          >
                            {item?.licenseType === 1
                              ? 'Commercial'
                              : item?.licenseType === 2
                              ? 'Extended'
                              : '0'}
                          </td> */}
                              <td
                                data-label="price"
                                className="stat-value pe-3"
                              >
                                #
                                {Math?.round(
                                  new Date(item?.createdAt).getTime() / 1000
                                )}
                              </td>
                              <td
                                data-label="earnings"
                                className="green stat-value"
                              >
                               
                                {item?.price !== 0
                                  ?  "$"+item?.price
                                  : 'TBD'}
                              </td>
                              <td data-label="earnings" className="stat-value">
                                {item?.isstatus ? 'Credited' : 'Pending'}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    )
                  )
                ) : (
                  <NoDataPage
                    title="Result Not Found"
                    image="/Image/Icons/NotFound.png"
                    desc="Please try again with another keywords or maybe use generic term"
                    status={true}
                    btnName="Go to Home"
                    path="/"
                  />
                )}
                {/* {data?.length < 1 && !loading && (
                  <NoDataPage
                    title="Result Not Found"
                    image="/Image/Icons/NotFound.png"
                    desc="Please try again with another keywords or maybe use generic term"
                  />
                )} */}
              </div>
            </div>
            {totalpage > 1 && <div className="d-flex justify-content-between mt-5  pt-10">
              <div className="my-2">
                <Pagination
                  count={totalpage}
                  page={currentpage}
                  onChange={handleChange}
                  variant="outlined"
                  shape="rounded"
                  className="pagination_"
                />
              </div>
              <div className="my-2">
                <div className="d-flex align-items-center pagination-drpdown">
                  <select
                    className="form-control pagination-drpdown1 dropdownPage"
                    id="kt_datatable_search_status"
                    onChange={(e) => handleonchnagespagination(e)}
                    value={pagesize}
                  >
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                    <option value={50}>50</option>
                  </select>
                </div>
              </div>
            </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default SalesStatement;
