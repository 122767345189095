import React, { useEffect, useState } from 'react';
import { FaBehance } from 'react-icons/fa';
import { AiOutlineGlobal, AiOutlineInstagram } from 'react-icons/ai';
import { FiTwitter, FiDribbble, FiGithub } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { FiCamera } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import Aside from '../../components/aside';
import { AiOutlineCloseCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { ApiGet, ApiPut, ApiUpload, Bucket } from '../../services/http-service';
import UseNav from 'src/Hooks/Header/UseNav';
import { ErrorToast, SuccessToast } from '../../../src/helpers/Toast';
import { profileUpdate } from 'src/redux/reducer/profileUpdateSlice';
import Loader from 'src/components/loader';
import { BsPlusLg, BsUpload } from 'react-icons/bs';
import Dropzone from 'react-dropzone';
import { ApiPost } from 'src/helpers/API/ApiData';
import Index1 from '../GetVerified/index1';
import Index2 from '../GetVerified/index2';
import Index3 from '../GetVerified/index3';

type FormData = {
  username: string;
  name: string;
  bio: string;
  websiteURL: string;
  twitterURL: string;
  instagramURL: string;
  dribbbleURL: string;
  behanceURL: string;
  githubURL: string;
  country: string;
  documentType: string;
  address: string;
  zipCode: string;
  state: string;
  city: string;
  mobileNumber: string;
};

const getVerified = () => {
  const navData = UseNav();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset } = useForm<FormData>();
  const [profileData, setProfileData] = React.useState<any>([]);
  const [profileData1, setProfileData1] = React.useState<any>([]);

  const [profileImage, setprofileImage] = React.useState<any>([]);
  const [countryData, setCountryData] = useState([]);
  const [countryValue, setCountryValue] = useState('');
  const [stateData, setStateData] = useState([]);
  const [stateValue, setStateValue] = useState('');
  const [cityData, setCityData] = useState([]);
  const [cityValue, setCityValue] = useState('');
  const [newData, setNewData] = useState<any>({});
  const [error, setError] = useState(false);

  const [bannerImage, setbannerImage] = React.useState<any>([]);
  const [isLoding, setisLoding] = React.useState<any>(false);
  const [Loding, setLoding] = React.useState<any>(true);
  // const [loading, setloading] = React.useState<boolean>(false);

  const [compressedProfileImage, saveCompressedProfileImage] =
    React.useState<any>('');
  const [compressedBannerImage, saveCompressedBannerImage] =
    React.useState<any>('');

  useQuery('fetchProfileData', () => ApiGet('/profile'), {
    onSuccess: (response: any) => {
      setLoding(false);
      setProfileData(response?.data);
      reset(response?.data);
      window.scrollTo(0, 0);
    }
  });

  const handleChange = (e: any) => {
    let { name, value } = e.target;
    setNewData({ ...newData, [name]: value });
  };
  const { mutateAsync: profileDataMutate } = useMutation(
    () => ApiGet('/profile'),
    {
      onSuccess: (response: any) => {
        setProfileData(response?.data);
        let obj = {
          ...response?.data,
          userType: navData?.parsedLoginData?.userType,
          token: navData?.parsedLoginData?.token
        };
        localStorage.setItem('logindata', JSON.stringify(obj));
        dispatch(profileUpdate(obj));
      }
    }
  );

  const { mutateAsync: profileInfoData } = useMutation(
    (data) => ApiPost('/profile_verification', data),
    {
      onSuccess: (res: any) => {
        profileDataMutate();
        SuccessToast(res?.message);
        setisLoding(false);
        getProfile();
        // ApiGet('/profile_verification')
        //   .then((res: any) => {
        //     SuccessToast(res?.message);
        //   })
        //   .catch((e: any) => {
        //     ErrorToast(e?.message);
        //   });
      },
      onError: (error: any) => {
        setisLoding(false);
        ErrorToast(error?.message);
      }
    }
  );

  const { mutateAsync: uploadProfilePhoto } = useMutation(
    (data) => ApiUpload('upload/compress_image/profile_image', data, {}),
    {
      onSuccess: (response: any) => {
        saveCompressedProfileImage(response?.data?.image);
      },
      onError: (error: any) => {
        setisLoding(false);
        ErrorToast(error?.message);
      }
    }
  );

  const { mutateAsync: uploadBanner } = useMutation(
    (data) => ApiUpload('upload/compress_image/profile_image', data, {}),
    {
      onSuccess: (response: any) => {
        saveCompressedBannerImage(response?.data?.image);
      },
      onError: (error: any) => {
        setisLoding(false);
        ErrorToast(error?.message);
      }
    }
  );

  const uploadProfileImage = async () => {
    const formData: any = new FormData();
    if (profileImage[0]?.fileURL) {
      formData.append('image', profileImage[0]);
      uploadProfilePhoto(formData);
    }
  };
  const uploadBannerImage = async () => {
    const formData: any = new FormData();
    if (bannerImage[0]?.fileURL) {
      formData.append('image', bannerImage[0]);
      uploadBanner(formData);
    }
  };

  const handleimage = (image: any) => {
    image.map((file: any) => {
      let fileURL = URL.createObjectURL(file);
      file.fileURL = fileURL;
      setprofileImage([file]);
    });
  };

  const handleimage2 = (e: any) => {
    let file = e.target.files[0];
    let fileURL = URL.createObjectURL(file);
    file.fileURL = fileURL;
    setbannerImage([file]);
  };

  const onSubmit = async (data: any) => {
    setisLoding(true);
    setError(false);
    // eslint-disable-next-line
    let { email, ...rest } = data;

    if (compressedProfileImage) {
      rest.image = compressedProfileImage || '';
    }
    if (compressedBannerImage) {
      rest.coverImage = compressedBannerImage || '';
    }
    window.scrollTo(0, 0);
    let country: any = countryData?.filter((v: any) => v?._id === countryValue);
    let state: any = stateData?.filter((v: any) => v?._id === stateValue);
    let city: any = cityData?.filter((v: any) => v?._id === cityValue);
    let body: any = {
      country: country[0]?.country,
      state: state[0]?.state,
      city: city[0]?.city,
      documentType: newData?.documentType,
      address: newData?.address,
      document: compressedProfileImage,
      zipcode: newData?.zipCode,
      mobilenumber: newData?.mobileNumber
    };

    if (
      countryValue &&
      stateValue &&
      cityValue &&
      newData?.documentType &&
      newData?.address &&
      compressedProfileImage &&
      newData?.zipCode &&
      newData?.mobileNumber
    ) {
      profileInfoData(body);
    } else {
      setError(true);
      setisLoding(false);
    }
  };
  const getCountry = () => {
    ApiGet('/country')
      .then((res: any) => {
        setCountryData(res?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getProfile = () => {
    ApiGet('/profile_verification_details')
      .then((res: any) => {
        setProfileData1(res?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getState = () => {
    ApiGet(`/country?countryId=${countryValue}`)
      .then((res: any) => {
        setStateData(res?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  const getCity = () => {
    ApiGet(`/country?countryId=${countryValue}&stateId=${stateValue}`)
      .then((res: any) => {
        setCityData(res?.data);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };
  React.useEffect(() => {
    profileImage?.length !== 0 && uploadProfileImage();
    bannerImage?.length !== 0 && uploadBannerImage();
  }, [profileImage, bannerImage]);

  useEffect(() => {
    getCountry();
    getProfile();
  }, []);
  useEffect(() => {
    getState();
  }, [countryValue]);
  useEffect(() => {
    getCity();
  }, [countryValue, stateValue]);

  return (
    <div className="primary-content-area container content-padding grid-left-sidebar">
      <Aside />
      <div className="main-content-area main-content-area-mobile">
        <div className="page-title">
          <h2>
            <span className="gradient-text">Get </span> Verified
          </h2>
        </div>
        {!Loding ? (
          <div className="user-db-content-area">
            {profileData?.isCreator ? (
              <Index2 />
            ) : profileData?.isverifyavailable && !profileData1?._id ? (
              <form
                className="cryptoki-form"
                id="personal-info-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-group row">
                  <div className="form-field col-md-6">
                    <label htmlFor="username">Country</label>
                    <select
                      id="username"
                      value={countryValue}
                      className="mb-0"
                      onChange={(e: any) => {
                        setCountryValue(e?.target?.value);
                        setStateValue('');
                        setCityValue('');
                      }}
                    >
                      <option value="" disabled>
                        select country
                      </option>
                      {countryData?.map((val: any) => {
                        return <option value={val?._id}>{val?.country}</option>;
                      })}
                    </select>
                    {error && !countryValue && (
                      <div className="text-danger">Country is required!</div>
                    )}
                  </div>
                  <div className="form-field col-md-6">
                    <label htmlFor="name">Document Type</label>
                    <select
                      id="name"
                      name="documentType"
                      className="mb-0"
                      onChange={(e) => handleChange(e)}
                      value={newData?.documentType}
                    >
                      <option value="">select document</option>
                      <option value="Driving License">Driving License</option>
                      <option value="Passport">Passport</option>
                      <option value="National identification card">
                        National identification card
                      </option>
                    </select>
                    {error && !newData?.documentType && (
                      <span className="text-danger">
                        Document Type is required!
                      </span>
                    )}
                  </div>
                </div>

                <div className="upload-container mt-4">
                  <div className="artwork-upload">
                    <div className="label">Upload your identity document</div>
                    <div className="upload-box">
                      <BsUpload className="mb-2" />
                      <div className="upload-notice mb-2">PDF, PNG, JPEG</div>
                      {profileImage.length !== 0 ? (
                        <div className="d-flex">
                          <p className="green mx-2 mt-10">
                            {profileImage[0]?.name ??
                              profileImage[0].split('/')[2]}
                          </p>
                          <AiOutlineCloseCircle
                            size={20}
                            className="fw-600 closeSource cursor-pointer closeColor"
                            onClick={() => setprofileImage([])}
                          ></AiOutlineCloseCircle>
                        </div>
                      ) : (
                        <Dropzone
                          onDrop={handleimage}
                          accept={'image/*'}
                          maxFiles={1}
                          multiple={false}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropZone" {...getRootProps()}>
                              <input {...getInputProps()} />
                              <div className="btn btn-normal btn-dark waves-effect waves-button waves-float waves-light">
                                Upload your Document
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      )}
                    </div>
                    {error && !compressedProfileImage && (
                      <span className="text-danger">Document is required!</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-field col-md-12">
                    <label htmlFor="name">Address</label>
                    <textarea
                      rows={4}
                      id="name"
                      name="address"
                      onChange={(e) => handleChange(e)}
                      value={newData?.address}
                    />
                    {error && !newData?.address && (
                      <span className="text-danger">Address is required!</span>
                    )}
                  </div>
                </div>
                <div className="form-group row mt-4">
                  <div className="form-field col-md-6">
                    <label htmlFor="username">State</label>
                    <select
                      id="username"
                      value={stateValue}
                      className="mb-0"
                      onChange={(e: any) => {
                        setStateValue(e?.target?.value);
                        setCityValue('');
                      }}
                    >
                      <option value="" disabled>
                        select state
                      </option>
                      {stateData?.map((val: any) => {
                        return <option value={val?._id}>{val?.state}</option>;
                      })}
                    </select>
                    {error && !stateValue && (
                      <span className="text-danger">State is required!</span>
                    )}
                  </div>
                  <div className="form-field col-md-6">
                    <label htmlFor="name">City</label>
                    <select
                      id="username"
                      value={cityValue}
                      className="mb-0"
                      onChange={(e: any) => {
                        setCityValue(e?.target?.value);
                      }}
                    >
                      <option value="" disabled>
                        select city
                      </option>
                      {cityData?.map((val: any) => {
                        return <option value={val?._id}>{val?.city}</option>;
                      })}
                    </select>
                    {error && !cityValue && (
                      <span className="text-danger">City is required!</span>
                    )}
                  </div>
                </div>
                <div className="form-group row">
                  <div className="form-field col-md-6">
                    <label htmlFor="username">Zip code</label>
                    <input
                      type="text"
                      id="username"
                      name="zipCode"
                      onChange={(e) => handleChange(e)}
                      value={newData?.zipCode}
                    />
                    {error && !newData?.zipCode && (
                      <span className="text-danger">ZipCode is required!</span>
                    )}
                  </div>
                  <div className="form-field col-md-6">
                    <label htmlFor="name">Mobile Number</label>
                    <input
                      type="text"
                      id="name"
                      name="mobileNumber"
                      onChange={(e) => handleChange(e)}
                      value={newData?.mobileNumber}
                    />
                    {error && !newData?.mobileNumber && (
                      <span className="text-danger">
                        Mobile Number is required!
                      </span>
                    )}
                  </div>
                </div>
                <button
                  className="btn btn-fullwidth gradient-background  border-none myBtnColor "
                  type="submit"
                  disabled={isLoding ? true : false}
                >
                  {isLoding ? 'Loading...' : 'Apply for verification'}
                </button>
              </form>
            ) : profileData?.isverifyavailable && profileData1?._id ? (
              <Index3 />
            ) : (
              <Index1 />
            )}
          </div>
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
};

export default getVerified;
