export const getTitleFromPath = (path: any) => {
    const segments = path.split('/'); // Split the path into segments
    const lastSegment = segments[segments.length - 1];
    let title = 'Free Fonts : Download Now for Your Next Project',
        description = 'Unicorn UI is a website for designers to find beautiful designs and graphics. Our goal is to offer the best high-quality content: UI Designs, UI kits, Logos, banner,icons, mockups.',
        itemH1 = 'Explore Items',
        itemP = 'explore items',
        itemSubline = ''


    if (lastSegment === 'Fonts' || lastSegment.includes('Fonts')) {
        title = 'Free Fonts : Download Now for Your Next Project'
        description = 'Over 1000+ free fonts are created to use in your design projects that will amplify the look of your design. Find your favorite fonts and download them now for free.',
            itemH1 = 'Free Fonts',
            itemSubline = `Created 1000+ amazing free fonts to match your design and attract anyone's eyes. Selecting the right kind of font can fill the gap between your design and work satisfaction. Browse and download your favorite free fonts here!
            `
        itemP = `
        Finding the perfect typeface for your project is really hard when you have to pay for it. We understand that pain of designer or developers. Choosing good fonts makes your users read content more carefully and engage with your site.
        <br>
        That's why we have made your work simpler and faster, we have listed the best free fonts for your next projects. Just explore it and find your favorite fonts to use in your personal and commercial use without worrying about copyright infringement.
        <br>
        Our collection of fonts is completely free with no catch!`

    }
    else if (lastSegment === 'Icons' || lastSegment.includes('Icons')) {
        title = 'Free Icons for Web and Apps | Download Now'
        description = 'Explore the largest and collective library of Free Icons to use in your design projects. Available in various icon styles and file formats such as SVG, PNG, and PSD.'
        itemH1 = 'Download Handpicked Free Icons',
            itemSubline = `Visual icons are an easy and creative way to explain to user what you are trying to say without writing hundreds of words. Explore more than 1000 free icons to use in website design, application design, logo, and infographics.`
        itemP = `<h2 style="font-size:14px">Free Icons presented by Unicorn UI</h2>
         
            
        
        Icons say it all. If are you looking for high-quality icons for free for your design projects, then we are go to a platform for graphic resource. 
        <br>
        
        Just search the keyword and get the best hand-picked icon sets you want within a few seconds and download it at no cost.
        <br>
        
        We are the biggest library for design resources and continuously adding new icons for you, so stay connected with us.
        <br>
        
        Make a website and application design, menus, worksheets, social posts, and business cards by getting creative with icons.`
    }

    // Mockups  ***************************************************************************************************
    else if (lastSegment === 'Mockups') {
        title = '800+ Free mockups to Download - Unicorn UI'
        description = ' Handpicked more than 800+ Free mockups to beautifully present your design project and bring it to life. Available in various styles and file formats.',
            itemH1 = 'Free Mockups for Your Work',
            itemSubline = `Give life to your design and feel that in a real way. We have created amazing mockup templates for branding, website, mobile application, product design, and printing media.`
        itemP = `

        Explore various styles of mockups to showcase your artwork and give final look. 
        A mockup helps designers visualize their designs and concepts in context before finalizing the project, giving them an early understanding of how their design will look before it is made and how it would be used. 
        <br>
        
        With interactive free mockups, even ideas that are in sketches can be brought to life!
        <br>
        
        Unicorn UI is the biggest collection of free mockups with varius styles and models like mobile and website, branding, products, packaging, print, clothes, foods, crafts, social media and more.
        <br>
        
        Here you can find all kind of mockups for your next projects. JUST ENJOY IT!`

    }
    else if (lastSegment === 'iOS Mockups' ) {
        title = 'Download iOS Mockups - 2D, 3D & Animated Templates'
        description = 'Make your app presentations stand out with these iOS Mockups. Easy to customize and get your design back to life. Free to download high quality iOS mockups.',
            itemH1 = 'Free Mockups for Your Work',
            itemSubline = `Give life to your design and feel that in a real way. We have created amazing mockup templates for branding, website, mobile application, product design, and printing media.`
        itemP = `

        Explore various styles of mockups to showcase your artwork and give final look. 
        A mockup helps designers visualize their designs and concepts in context before finalizing the project, giving them an early understanding of how their design will look before it is made and how it would be used. 
        <br>
        
        With interactive free mockups, even ideas that are in sketches can be brought to life!
        <br>
        
        Unicorn UI is the biggest collection of free mockups with varius styles and models like mobile and website, branding, products, packaging, print, clothes, foods, crafts, social media and more.
        <br>
        
        Here you can find all kind of mockups for your next projects. JUST ENJOY IT!`

    }
    else if (lastSegment === 'Android Mockups' ) {
        title = 'Free Android Mockups to Download | Unicorn UI'
        description = 'Download customizable free Android mockup including mobile, and tablet. Get your designs back to life and live them in real-time.',
            itemH1 = 'Free Mockups for Your Work',
            itemSubline = `Give life to your design and feel that in a real way. We have created amazing mockup templates for branding, website, mobile application, product design, and printing media.`
        itemP = `

        Explore various styles of mockups to showcase your artwork and give final look. 
        A mockup helps designers visualize their designs and concepts in context before finalizing the project, giving them an early understanding of how their design will look before it is made and how it would be used. 
        <br>
        
        With interactive free mockups, even ideas that are in sketches can be brought to life!
        <br>
        
        Unicorn UI is the biggest collection of free mockups with varius styles and models like mobile and website, branding, products, packaging, print, clothes, foods, crafts, social media and more.
        <br>
        
        Here you can find all kind of mockups for your next projects. JUST ENJOY IT!`

    }
    else if (lastSegment === 'Web Mockups' ) {
        title = 'Free Web Mockup to Show Up Your Designs'
        description = 'Download an amazing free web mockup to get your designs back to life. Free for personal and commercial use. Explore now.',
            itemH1 = 'Free Mockups for Your Work',
            itemSubline = `Give life to your design and feel that in a real way. We have created amazing mockup templates for branding, website, mobile application, product design, and printing media.`
        itemP = `

        Explore various styles of mockups to showcase your artwork and give final look. 
        A mockup helps designers visualize their designs and concepts in context before finalizing the project, giving them an early understanding of how their design will look before it is made and how it would be used. 
        <br>
        
        With interactive free mockups, even ideas that are in sketches can be brought to life!
        <br>
        
        Unicorn UI is the biggest collection of free mockups with varius styles and models like mobile and website, branding, products, packaging, print, clothes, foods, crafts, social media and more.
        <br>
        
        Here you can find all kind of mockups for your next projects. JUST ENJOY IT!`

    }

    // UI KITS  ***************************************************************************************************
    else if (lastSegment === 'UI Kits' || lastSegment === 'Other UI Kits' ) {
        title = 'Download Free UI Kits - Unicorn UI'
        description = 'Find and download free UI kits for your next project to save you time. Readymade UI kits for Adobe Illustrator, Adobe Photoshop, Adobe XD, Figma, and Sketch.',
            itemH1 = 'Free UI Kits to Simplify Workflow',
            itemSubline = `Are you looking for unique design ideas to make the UI of your web and app more attractive?, here created 1000+ UI kits for your next projects to save your worthy time. Download free for both personal & commercial use.`
        itemP = `<h2 style="font-size:14px">Use free UI Kits to create amazing designs faster</h2>
            
        If you want to make your web or application's design awesome and far better than your competitors, you'll need unique ideas and concepts. But it's too hard to find.
        <br>
        
        Don't worry, we made it easy for you. Explore our 1000+ UI kits and select any one according to your need and projects.
        <br>
        
        Save your hours and deliver results faster before the deadline.
        <br>
        
        Thankfully, we have created all these amazing UI kits for free to download and use for your personal and commercial use.
`
    }
    else if (lastSegment === 'iOS UI Kits') {
        title = 'Free iOS UI Kits for Figma, XD and Sketch'
        description = 'Explore amazing iOS UI Kits for your next project. Easy to customize in Figma, Adobe XD, and Sketch. Available over 500+ free iOS UI Kits.',
            itemH1 = 'Free UI Kits to Simplify Workflow',
            itemSubline = `Are you looking for unique design ideas to make the UI of your web and app more attractive?, here created 1000+ UI kits for your next projects to save your worthy time. Download free for both personal & commercial use.`
        itemP = `<h2 style="font-size:14px">Use free UI Kits to create amazing designs faster</h2>
            
        If you want to make your web or application's design awesome and far better than your competitors, you'll need unique ideas and concepts. But it's too hard to find.
        <br>
        
        Don't worry, we made it easy for you. Explore our 1000+ UI kits and select any one according to your need and projects.
        <br>
        
        Save your hours and deliver results faster before the deadline.
        <br>
        
        Thankfully, we have created all these amazing UI kits for free to download and use for your personal and commercial use.
`
    }
    else if (lastSegment === 'Android UI Kits') {
        title = 'Free Android UI Kits for Figma'
        description = 'Explore 800+ Android UI Kits for your next application design project. Easy to customize in Figma, Adobe XD, and Sketch. Browse our manually curated collection of UI kits.',
            itemH1 = 'Free UI Kits to Simplify Workflow',
            itemSubline = `Are you looking for unique design ideas to make the UI of your web and app more attractive?, here created 1000+ UI kits for your next projects to save your worthy time. Download free for both personal & commercial use.`
        itemP = `<h2 style="font-size:14px">Use free UI Kits to create amazing designs faster</h2>
            
        If you want to make your web or application's design awesome and far better than your competitors, you'll need unique ideas and concepts. But it's too hard to find.
        <br>
        
        Don't worry, we made it easy for you. Explore our 1000+ UI kits and select any one according to your need and projects.
        <br>
        
        Save your hours and deliver results faster before the deadline.
        <br>
        
        Thankfully, we have created all these amazing UI kits for free to download and use for your personal and commercial use.
`
    }
    else if (lastSegment === 'Web UI Kits') {
        title = 'Download Free Web UI Kits - Unicorn UI'
        description = 'Download free web UI kits and deliver results faster. Easy to customize, no more headaches, and free to download.',
            itemH1 = 'Free UI Kits to Simplify Workflow',
            itemSubline = `Are you looking for unique design ideas to make the UI of your web and app more attractive?, here created 1000+ UI kits for your next projects to save your worthy time. Download free for both personal & commercial use.`
        itemP = `<h2 style="font-size:14px">Use free UI Kits to create amazing designs faster</h2>
            
        If you want to make your web or application's design awesome and far better than your competitors, you'll need unique ideas and concepts. But it's too hard to find.
        <br>
        
        Don't worry, we made it easy for you. Explore our 1000+ UI kits and select any one according to your need and projects.
        <br>
        
        Save your hours and deliver results faster before the deadline.
        <br>
        
        Thankfully, we have created all these amazing UI kits for free to download and use for your personal and commercial use.
`
    }
    else if (lastSegment === 'illustrations' || lastSegment.includes('illustrations')) {
        title = '1500+ Free Illustrations to Download - Unicorn UI'
        description = 'Explore a wide range of free illustrations in all categories to enhance your design and user experience. Download in PNG and SVG formats for personal and commercial use.',
            itemH1 = 'Free Illustrations to Download',
            itemSubline = `  Free illustrations are a great resource for designers to use in websites, applications, posters, documents, and card designs as they enhance the experience of design and keep users engaged for a long time to improve conversions. 
        `
        itemP = `<h2 style="font-size:14px">Add value to your design using free Illustrations</h2>
        Plain and simple texts without creative graphics makes your design boring and unattractive. Users don't want to read your plain text or content, they need something interesting to engage with your design.
        <br>
        
        Therefore, our free illustrations adds some value to your design that grabs the user's attention.
        The best part about free vector illustrations is that you don't need to be an artist or designer to use them. All you have to do is download them and use them in your design project.`

    }



    // Templates  ***************************************************************************************************
    else if (lastSegment === 'Templates' || lastSegment === 'Other Templates' ) {
        title = 'Explore 2000+ Free Templates 2023'
        description = 'Want to make your design faster and more creative, explore 2000+ premade free templates. Download creative customizable templates to use in your work.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Android Templates' ) {
        title = 'Download Free Android Templates- Unicorn UI'
        description = 'Download free handpicked Android templates for your real-life projects. Simplify your creative workflow.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'iOS Templates' ) {
        title = 'Download Free IOS UI Templates - Unicorn UI'
        description = 'Download free handpicked IOS templates for your real-life projects. Simplify your creative workflow.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Web Templates' ) {
        title = '500+ Free Web Templates to Download 2023'
        description = 'Download over 500+ free web templates including android, ios and web templates. Simplify your creative workflow and deliver result faster then ever.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Logo Templates' ) {
        title = 'Free Customizable Logo Templates - Download Now'
        description = 'Download amazing free logo templates for businesses, restaurants, sports, and more to unique brand identity that represents your business.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Business Card Templates' ) {
        title = 'Free Business Card Templates - Download Now'
        description = 'Download free business card templates for your personal and commercial use. Easy to customize and share with anyone.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Email Templates' ) {
        title = '500+ Free Email Templates - Download Now'
        description = 'Beutifully crafted over 500 email templates to download for your business. Choose any template and launch your email marketing campaign.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Infographics Templates' ) {
        title = 'Free Infographic Templates, and Designs - Unicorn UI'
        description = 'Explore 10,000+ fully customizable infographic templates and designs to use for your personal and commercial use.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Social Media Templates' ) {
        title = 'Free Social Media Templates - Download Now'
        description = 'Download free social media templates for all platforms like Instagram, Facebook, LinkedIn and Pinterest. Easy to customize and share with anyone.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }
    else if (lastSegment === 'Banner Templates' ) {
        title = 'Download Free Banner Templates for Your Project'
        description = 'Here download over 500+ free banner templates for your next project and customize them according to your needs. Also, take inspiration for your next design.',
            itemH1 = 'Free Templates to Download',
            itemSubline = ` Make your design creative and deliver it faster by downloading our free templates for social media, email newsletters, business cards, posters, and more you'll want.`
        itemP = `<h2 style="font-size:14px">Accelerate your design workflow</h2>
           
        Explore more than 500 amazing templates to get your work done effectively.
        <br>
        
         Accelerate your design workflow
         <br>
        
        Sometimes starting from scratch become a headache to designers, but what if you have ready-made templates and you just need to edit them?
        <br>
        
        Create your design projects in a more enjoyable manner, without feeling overwhelmed. Our collection of pre-designed templates are made specifically for you, so you don't need any design skills or experience to use them.
        <br>
        
        All you have to do is choose the template you want, customize it according to your needs by changing the text, images, and colours, and then save your final design. It's that easy!
        `
    }

    else {
        title = 'Unicorn UI - Where design begins!'
        description = 'Unicorn UI is a website for designers to find beautiful designs and graphics. Our goal is to offer the best high-quality content: UI Designs, UI kits, Logos, banner,icons, mockups.',
            itemH1 = 'Explore Items',
            itemP = 'explore items',
            itemSubline = ''
    }
    return { title, description, itemH1, itemP, itemSubline }
}
