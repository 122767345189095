import React from 'react';
import { useHistory } from 'react-router-dom';

const NoDataPage = (props: any) => {
  const history = useHistory();

  return (
    <div className="text-center">
      <img src={props?.image} className="no-data my-5" />

      <div className="no-data-title">{props?.title}</div>

      <div className="subTitle">{props?.desc}</div>
      {props?.status && (
        <button
          className="btn text-center w-30 mt-3 py-3 cursor-pointer gradient-background mb-3"
          type="button"
          onClick={() => history.push(props?.path)}
        >
          {props?.btnName}
        </button>
      )}
    </div>
  );
};

export default NoDataPage;

{
  /* <div className={`no-data-title ${props?.title.toLowerCase().includes("notification")}? fs-5 :fs-6  `}> */
}
