import React, { useEffect, useState } from 'react';
import { AiFillFacebook } from 'react-icons/ai';
import { AiOutlineTwitter } from 'react-icons/ai';
import {
  BsInstagram,
  BsPinterest,
  BsYoutube,
  BsLinkedin,
  BsDribbble,
  BsBehance
} from 'react-icons/bs';
import { FaTelegramPlane } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { ApiGetNoAuth, Bucket } from 'src/helpers/API/ApiData';

const Footer = () => {
  const [data, setData] = useState<any>([]);
  const getAdv = async () => {
    ApiGetNoAuth('user/adv').then((res: any) => {
      let dymmy = [];
      let dummyData = [
        {
          image: '642405084c10e35ba8e4c8c6/profile_image/1680516523510.png',
          title: 'Welcome to the Unicorn UI!',
          description: 'Browse the Best Digital Products!'
        }
      ];
      for (let i = 0; i < 1; i++) {
        for (let j = 0; j < res.data.data?.length; j++) {
          dymmy.push(res.data.data[j]);
        }
      }

      if (dymmy.length === 0) {
        setData(dummyData);
      } else {
        setData(dymmy);
      }
    });
  };
  useEffect(() => {
    getAdv();
  }, []);
  const currentPageName = location.pathname;

  return currentPageName !== '/offer' ? (
    <footer className="footer footer-margin">
      <div className="footer-wrapper container">
        <div className="subscribe-widget-v2">
          <div className="animated-img">
            {data?.length === 1 && (
              <img
                src={`${Bucket}${data[0]?.image}`}
                alt=""
                className="h-100 w-100 rounded-4 object-fit-cover"
                style={{ objectFit: 'cover' }}
              />
            )}
          </div>
          <div className="subscribe-text">
            <div className="h2" style={{ textShadow: '0 0 20px black' }}>
              Join the Creative Community and Earn!
            </div>
            <div
              className="subtitle incres-font"
              style={{ textShadow: '0 0 25px black' }}
            >
              Discover your next design inspiration and unlock new opportunities
              with Unicorn UI and The perfect platform for designers who want to
              earn a living doing what they love
            </div>
          </div>
          {/* <div className="subscribe-form-v2">
            <form
              className="cryptoki-subscribe-form"
              action="https://html.crumina.net/cryptoki/ds/forms/submit.php"
            >
              <input
                className="subscribe-input"
                type="email"
                name="email"
                placeholder="Subscribe to our newsletter"
              />
              <button
                className="subscribe-submit btn btn-dark waves-effect waves-button waves-float waves-light"
                type="submit"
              >
                <span className="button-text">Subscribe</span>
                <FaTelegramPlane />
              </button>
            </form>
          </div> */}
        </div>

        <div className="footer-content flex-space-between">
          <div className="footer-column footer-column-large">
            <div className="footer-widget">
              <div className="logo">
                <a
                  className="logo-link"
                  href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/01-index.html"
                >
                  <div className="new-logo1 logo-img mobile-logo-res">
                    <img src={'/Image/white_logo.png'} alt="logo" />
                  </div>
                  {/* <div className="logo-text">Unicorn UI</div> */}
                </a>
              </div>
              <div className="footer-text">
                <p className="footer-text-pera">
                  Designed to support creators accelerate their workflow. We
                  daily share our updates on these platform 👇
                </p>
              </div>
              <ul className="social-icons-list">
                {/* <li className="social-icon d-inline-block ">
                  <Link to="">
                    <AiFillFacebook className="footerIcon crumina-icon" />
                  </Link>
                </li>
                <li className="social-icon d-inline-block ">
                  <Link to="">
                    <AiOutlineTwitter className="footerIcon crumina-icon" />
                  </Link>
                </li> */}
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://in.pinterest.com/unicornui_official/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsPinterest className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open('https://www.youtube.com/@unicornui', '_blank')
                  }
                >
                  <div>
                    <BsYoutube className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://www.instagram.com/unicornui.official/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsInstagram className="footerIcon crumina-icon" />
                  </div>
                </li>
                <li
                  className="social-icon d-inline-block "
                  onClick={() =>
                    window?.open(
                      'https://www.linkedin.com/company/86402313/admin/',
                      '_blank'
                    )
                  }
                >
                  <div>
                    <BsLinkedin className="footerIcon crumina-icon" />
                  </div>
                </li>
                {/* <li className="social-icon d-inline-block ">
                  <Link to="">
                    <BsDribbble className="footerIcon crumina-icon" />
                  </Link>
                </li>
                <li className="social-icon d-inline-block ">
                  <Link to="">
                    <BsBehance className="footerIcon crumina-icon" />
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-widget">
              <div className="widget-title border-gradient">Resources</div>
              <div className="is-two-col-element">
                <ul className="footer-menu">
                  <li className="menu-item">
                    <Link className="menu-link" href="#" to="/">
                      Home
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/blog_list">
                      Our Blog
                    </Link>
                  </li>
                  <li className="menu-item">
                    <a className="menu-link" href="/#Creators">
                      Creators
                    </a>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/faq">
                      FAQs
                    </Link>
                  </li>
                  <li className="menu-item">
                    <Link className="menu-link" to="/contact-us">
                      Contact us
                    </Link>
                  </li>
                </ul>
                {/* <ul className="footer-menu">
                  <li className="menu-item">
                    <a
                      className="menu-link"
                      href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/08-profile-page.html"
                    >
                      Profile Page
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      className="menu-link"
                      href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/03-explore-v2.html"
                    >
                      Artwork Page
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      className="menu-link"
                      href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/17-activity.html"
                    >
                      Activity
                    </a>
                  </li>
                  <li className="menu-item">
                    <a
                      className="menu-link"
                      href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/26-upload-artwork.html"
                    >
                      Upload Art
                    </a>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-widget">
              <div className="widget-title border-gradient">Company</div>
              <ul className="footer-menu">
                <li className="menu-item">
                  <Link className="menu-link" to="/about-us">
                    About US
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/license">
                    Unicorn UI Licences
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/privacy">
                    Privacy Policy
                  </Link>
                </li>
                <li className="menu-item">
                  <Link className="menu-link" to="/terms-of-use">
                    Terms of Use
                  </Link>
                </li>
                <li className="menu-item">
                  <a
                    className="menu-link"
                    href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/03-explore-v2.html"
                  >
                    Career
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-column">
            <div className="footer-widget">
              <div className="widget-title border-gradient">Helpful Links</div>
              <ul className="footer-menu">
                <li className="menu-item">
                  <Link className="menu-link" to="/upload">
                    Become a seller
                  </Link>
                </li>
                {/* <li className="menu-item">
                  <a
                    className="menu-link"
                    href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/24-become-a-creator.html"
                  >
                    Become our affiliate
                  </a>
                </li> */}
                <li className="menu-item">
                  <Link className="menu-link" to="/plan">
                    Subscription plans
                  </Link>
                </li>
                {/* <li className="menu-item">
                  <a
                    className="menu-link"
                    href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/%21.html#"
                  >
                    About Us
                  </a>
                </li>
                <li className="menu-item">
                  <a
                    className="menu-link"
                    href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/20-contact.html"
                  >
                    Contact
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-copyright flex-space-between">
          <span className="copyright-text">
            Unicorn UI 2023 - All Rights Reserved
          </span>
          {/* <ul className="sub-footer-menu">
            <li className="menu-item">
              <a
                className="menu-link"
                href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/%21.html#"
              >
                Terms of Service
              </a>
            </li>
            <li className="menu-item">
              <Link
                to="/privacy"
                className="menu-link"
                href="file:///C:/Users/Admin/OneDrive/Desktop/HTML%20Cryptoki/cryptoki/nft/%21.html#"
              >
                Privacy
              </Link>
            </li>
          </ul> */}
        </div>
      </div>
    </footer>
  ) : null;
};

export default Footer;
