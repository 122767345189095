import React, { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import OneSignal from 'react-onesignal';

import AuthProtected from './helpers/route/authProtected';
import AuthPrevented from './helpers/route/authPrevent';
import { getFilter } from './redux/reducer/searchFilterSlice';
import HomePage from './Pages/home/HomePageContainer';
import NavBar from './layout/header/navbar';
import Header from './layout/header';
import Footer from './layout/footer';
import ProductPage from './Pages/products-detail';
import ProfileInfo from './Pages/profile/ProfileInfo';
import SalesStatement from './Pages/profile/SalesStatement';
import Payouts from './Pages/profile/Payouts';
import YourPurchases from './Pages/profile/YourPurchases';
import Plandetails from './Pages/profile/Plandetails';
import ForgotPassword from './Pages/profile/ForgotPassword';
import MyPost from './Pages/profile/MyPost';
import MyDownload from './Pages/profile/MyDownload';
import LikedPost from './Pages/profile/LikedPost';
import MyProfile from './Pages/profile/MyProfile';
import ExploreItems from './Pages/explore-items';
import Upload from './Pages/upload';
import contact from './Pages/ContactUs';
import about from './Pages/AboutUs';
import faq from './Pages/FrequentlyAskedQuestions';
import notifications from './Pages/Notifications';
import getVerified from './Pages/GetVerified/index';
import offer from './Pages/Offer/index';

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import './assets/css/Style1.css';
import './assets/css/Style2.css';
import './assets/css/Style3.css';
import './assets/css/Stytle4.css';
import './assets/css/login-register.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import AccountSetting from './Pages/profile/AccountSetting';
import SavedPost from './Pages/profile/SavedPost';
import Checkout from './Pages/profile/Checkout';
import Plan from './Pages/Plan';
import Plansuccess from './Pages/Plansuccess';
import Planerror from './Pages/Planerror';

import Blog_List from './Pages/Blog/Blog_List';
import Blog_details from './Pages/Blog/Blog_details';
import Manage_Account from './Pages/profile/Manage_Account';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import License from './Pages/License';
import Offer from './Pages/Offer';
import Privacy from './Pages/Privacy/Privacy';
import TermsOfUse from './Pages/TermsOfUse/TermsOfUse';
import Creator from './Pages/home/BecomeCreator';
import Benefits from './Pages/home/Benefits';

function App() {
  const searchFilter = useSelector(getFilter);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  });

  useEffect(() => {
    OneSignal.init({
      appId: '072509ab-ee16-4ad6-b691-f07c03845bae',
      // allowLocalhostAsSecureOrigin: true,
      // autoRegister: true,
      autoResubscribe: true
    });
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Toaster position="top-center" reverseOrder={false} />
        <Header />
        <NavBar />
        <Switch>
          <AuthPrevented key="offer" exact path="/offer" component={offer} />
          <AuthPrevented
            key="default-home"
            exact
            path="/"
            component={searchFilter ? ExploreItems : HomePage}
          />
          <AuthProtected
            key="account-setting"
            exact
            path="/getVerified"
            component={searchFilter ? ExploreItems : getVerified}
          />
          <AuthPrevented
            key="home"
            exact
            path="/dashboard"
            component={searchFilter ? ExploreItems : HomePage}
          />
          <AuthPrevented
            key="explore-items:id"
            exact
            path="/explore-items/:id"
            component={ExploreItems}
          />
          <AuthPrevented
            key="explore-items"
            exact
            path="/explore-items"
            component={ExploreItems}
          />
          {/* <AuthPrevented
            key="Icons"
            exact
            path="/Icons"
            component={ExploreItems}
          /> */}
          <AuthPrevented
            key="license"
            exact
            path="/license"
            component={License}
          />
          <AuthPrevented key="offer" exact path="/offer" component={Offer} />
          <AuthPrevented
            key="about-us"
            exact
            path="/about-us"
            component={about}
          />
          <AuthPrevented
            key="creator"
            exact
            path="/creator"
            component={Creator}
          />
          <AuthPrevented
            key="benefits"
            exact
            path="/benefits"
            component={Benefits}
          />
          <AuthPrevented
            key="privacy"
            exact
            path="/privacy"
            component={Privacy}
          />
          <AuthPrevented
            key="terms-of-use"
            exact
            path="/terms-of-use"
            component={TermsOfUse}
          />
          <AuthPrevented key="faq" exact path="/faq" component={faq} />
          <AuthPrevented
            key="contact"
            exact
            path="/contact-us"
            component={contact}
          />
          <AuthPrevented
            key="productdetail"
            exact
            path="/productdetail/:name/:id"
            component={searchFilter ? ExploreItems : ProductPage}
          />
          <AuthPrevented
            key="public-profile"
            exact
            path="/public/:userId/profile"
            component={searchFilter ? ExploreItems : MyProfile}
          />
          <AuthProtected
            key="profileinfo"
            exact
            path="/profileinfo"
            component={searchFilter ? ExploreItems : ProfileInfo}
          />
          <AuthProtected
            key="sales-statement"
            exact
            path="/sales-statement"
            component={searchFilter ? ExploreItems : SalesStatement}
          />
          <AuthProtected
            key="payouts"
            exact
            path="/payouts"
            component={searchFilter ? ExploreItems : Payouts}
          />
          <AuthProtected
            key="YourPurchases"
            exact
            path="/YourPurchases"
            component={searchFilter ? ExploreItems : YourPurchases}
          />
          <AuthProtected
            key="Plandetails"
            exact
            path="/Plandetails"
            component={searchFilter ? ExploreItems : Plandetails}
          />
          <AuthProtected
            key="change-password"
            exact
            path="/change-password"
            component={searchFilter ? ExploreItems : ForgotPassword}
          />
          <AuthProtected
            key="my-posts"
            exact
            path="/my-posts"
            component={searchFilter ? ExploreItems : MyPost}
          />
          <AuthProtected
            key="my-downloads"
            exact
            path="/my-downloads"
            component={searchFilter ? ExploreItems : MyDownload}
          />
          <AuthProtected
            key="liked-posts"
            exact
            path="/liked-posts"
            component={searchFilter ? ExploreItems : LikedPost}
          />
          <AuthProtected
            key="saved-posts"
            exact
            path="/saved-posts"
            component={searchFilter ? ExploreItems : SavedPost}
          />
          <AuthProtected
            key="upload"
            exact
            path="/upload"
            component={searchFilter ? ExploreItems : Upload}
          />

          <AuthProtected
            key="notifications"
            exact
            path="/notifications"
            component={searchFilter ? ExploreItems : notifications}
          />
          <AuthProtected
            key="account-setting"
            exact
            path="/account-setting"
            component={searchFilter ? ExploreItems : AccountSetting}
          />
          <AuthProtected
            key="checkout"
            exact
            path="/checkout/:id"
            component={searchFilter ? ExploreItems : Checkout}
          />
          <AuthPrevented
            key="plan"
            exact
            path="/plan"
            component={searchFilter ? ExploreItems : Plan}
          />
          <AuthPrevented
            key="plansuccess"
            exact
            path="/plansuccess"
            component={searchFilter ? ExploreItems : Plansuccess}
          />
          <AuthPrevented
            key="planerror"
            exact
            path="/planerror"
            component={searchFilter ? ExploreItems : Planerror}
          />
          <AuthPrevented
            key="Blog"
            exact
            path="/blog_list"
            component={searchFilter ? ExploreItems : Blog_List}
          />
          <AuthPrevented
            key="Blog"
            exact
            path="/blog_details"
            component={searchFilter ? ExploreItems : Blog_details}
          />
          <AuthProtected
            key="manage Account"
            exact
            path="/manage_account"
            component={searchFilter ? ExploreItems : Manage_Account}
          />
        </Switch>
        <Footer />
      </QueryClientProvider>
    </>
  );
}

export default App;
