import React from 'react'
import { useHistory } from 'react-router-dom';

const Planerror = () => {
  const history = useHistory();

  return (
    <div><img src={'/Image/error.png'} alt="avatar" onClick={()=>history.push(`/`)}  style={{width:"100%"}}/></div>
  )
}

export default Planerror