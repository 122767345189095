import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { profileUpdate } from 'src/redux/reducer/profileUpdateSlice';
import { ApiGet } from 'src/services/http-service';

const Plansuccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const callupdateuser = (id: any) => {
    ApiGet('/subscriptionupdate/' + id)
      .then((res: any) => {
       
        let local = JSON.parse(localStorage.getItem('logindata') || '');
        let data = {
          ...local,
          activeplan: res.data.extra.activeplan,
          planexpiry: res.data.extra.planexpiry
        };
        localStorage.setItem('logindata', JSON.stringify(data));
        localStorage.setItem(
          'planexpire',
          JSON.stringify(res.data.extra.planexpiry)
        );
        dispatch(profileUpdate(data));
      })
      .catch((err: any) => {
        window.console.log(err);
      });
  };
  const callupdateuserpurchase = (id: any) => {
   
    ApiGet('/purchaseupdate/' + id)
      .then((res: any) => {
        console.log('res', res);
      })
      .catch((err: any) => {
        window.console.log(err);
      });
  };
  useEffect(() => {
    // const queryString:any = new URL(window.location.toLocaleString());
    // const product = queryString.get('session_id') as any;
    const queryString: any = window.location.search;
    const searchParams = new URLSearchParams(queryString);
    if (searchParams.getAll('session_id').length > 0) {
      callupdateuser(searchParams.getAll('session_id')[0]);
    } else if (searchParams.getAll('purchase_id').length > 0) {
      callupdateuserpurchase(searchParams.getAll('purchase_id')[0]);
    }
    // if(queryString.split("?")[1])

    // // const urlParams:any = new URLSearchParams(queryString);
    

    // const params = new Proxy(new URLSearchParams(window.location.search), {
    //   get: (searchParams, prop:any) => searchParams.get(prop),
    // });
   
  }, []);

  return (
    <div>
      <img
        src={'/Image/success.png'}
        alt="avatar"
        onClick={() => history.push(`/`)}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default Plansuccess;
