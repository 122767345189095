import React from 'react';
import { FiUserCheck } from 'react-icons/fi';
import { TbRefresh } from 'react-icons/tb';
import { IoIosImages } from 'react-icons/io';
import { IoDocumentLock } from 'react-icons/io5';

const Creative = () => {
  return (
    <div className="mobile-response-seller">
      <div className="text-center w-100">
        <div className="section-title">
          The only go-to platform for
          <span className="gradient-text"> creators like you</span>
        </div>
        <div className="fs-6 word-space">
          Whether you are a designer, developer, explorer, or anyone, people
          love us for reasons.
        </div>
      </div>
      <div className="row w-100 align-items-center">
        <div className="col-lg-2 col-md-12 col-sm-12"></div>
        <div className="col-lg-4 col-md-12 col-sm-12">
          <div className="d-flex">
            <div className="icon-degree">
              <IoIosImages style={{ width: '21px', height: '21px' }} />
            </div>
            <div className="ms-3  w-75">
              <h3 className="text-high">High-quality stock content</h3>
              <p className="subTitle">
                Browse and download high-quality graphics and creative resources
                for free with no limitations.
              </p>
            </div>
          </div>
          <div className="d-flex mt-4">
            <div className="icon-degree">
              <TbRefresh style={{ width: '21px', height: '21px' }} />
            </div>
            <div className="ms-3  w-75">
              <h3 className="text-high">Everyday new assets</h3>
              <p className="subTitle">
                Our community of contributors daily adds fresh and trendy
                content to help each other.
              </p>
            </div>
          </div>
          <div className="d-flex mt-4">
            <div className="icon-degree">
              <IoDocumentLock style={{ width: '21px', height: '21px' }} />
            </div>
            <div className="ms-3 w-75">
              <h3 className="text-high">License included</h3>
              <p className="subTitle">
                Every creative asset you explore is guaranteed and available for
                personal or commercial use. Read our license terms to know more.
              </p>
            </div>
          </div>

          <div className="d-flex mt-4">
            <div className="icon-degree">
              <FiUserCheck style={{ width: '21px', height: '21px' }} />
            </div>
            <div className="ms-3  w-75">
              <h3 className="text-high">Made by professionals for designers</h3>
              <p className="subTitle">
                We're a team of creative minds behind Unicornui.com and all
                creative resources are made by professionals for designers
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12">
          <img src="Image/home-creative.png" alt="" />
        </div>
        <div className="col-lg-2 col-md-12 col-sm-12"></div>
      </div>
    </div>
  );
};

export default Creative;
