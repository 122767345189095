import React, { useEffect, useState } from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import { BsArrowRight } from 'react-icons/bs';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { Row } from 'reactstrap';

import SwiperCore, { FreeMode, Navigation, Pagination, Keyboard } from 'swiper';
import UseNav from 'src/Hooks/Header/UseNav';
import { Link } from 'react-router-dom';
import { ApiPostNoAuth, Bucket } from 'src/helpers/API/ApiData';
import { IoCloseOutline } from 'react-icons/io5';
import ProductDetailsModal from 'src/components/itemCard/ProductDetailsModal';
import { HiCheck } from 'react-icons/hi';
SwiperCore.use([FreeMode, Navigation, Pagination, Keyboard]);

const HtmlTwoSlider = () => {
  const navData = UseNav();
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [productId, setProductId] = useState('');
  const [loader, setloader] = useState(false);
  const getData = async () => {
    setloader(true);
    // e.preventDefault();
    let body = {
      page: 1,
      limit: 10
    };
    ApiPostNoAuth('user/get_featureuser', body)
      .then((res: any) => {
        setData(res?.data?.data?.response);
        setloader(false);
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="primary-content-area " id="Creators">
      <div className="container">
        <div
          className="section-title"
          style={{ position: 'relative', top: '50px' }}
        >
          <span className="gradient-text">Creators </span>of the week
        </div>
        {/* <div className="" style={{ fontSize: '16px' }}>
          <div>
            Stay updated with current and upcoming creators from our community
          </div>
        </div> */}

        {loader ? (
          <div className="row">
            <div className="col-md-6">
              <div className="shimmer" style={{ paddingTop: '90px' }}>
                <div className="wrapper">
                  <div className="image-card animate"></div>
                  <div className="stroke animate titleeee"></div>
                  <div className="stroke animate link"></div>
                  <div className="stroke animate description"></div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shimmer" style={{ paddingTop: '90px' }}>
                <div className="wrapper">
                  <div className="image-card animate"></div>
                  <div className="stroke animate titleeee"></div>
                  <div className="stroke animate link"></div>
                  <div className="stroke animate description"></div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Swiper
            style={{ paddingTop: '90px' }}
            slidesPerView={1}
            navigation={true}
            keyboard={true}
            spaceBetween={2}
            freeMode={true}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false
            }}
            breakpoints={{
              1000: {
                slidesPerView: 2
              }
            }}
            className="slide1"
          >
            {data?.map((val: any) => {
              return (
                <SwiperSlide>
                  <div
                    className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-next"
                    data-swiper-slide-index="1"
                    role="group"
                    aria-label="2 / 2"
                  >
                    <div className="seller-card">
                      <div className="about-seller">
                        <div className="seller-info">
                          <div className="avatar box-64">
                            <Link
                              to={`/public/${val?._id}/profile`}
                              target="_blank"
                            >
                              <img
                                src={
                                  val?.image
                                    ? val?.image.includes('googleusercontent')
                                      ? val?.image
                                      : Bucket + val?.image
                                    : '/Image/avatar.png'
                                }
                                alt="avatar"
                              />
                            </Link>
                            {val?.isCreator && (
                              <span className="verified">
                                <svg className="crumina-icon">
                                  <HiCheck />
                                </svg>
                              </span>
                            )}
                          </div>
                          <div className="seller-meta">
                            <div className="title">{val?.name}</div>
                            <div
                              className="meta"
                              onClick={() =>
                                window?.open(
                                  `/public/${val?._id}/profile`,
                                  '_blank'
                                )
                              }
                            >
                              @{val?.username}
                            </div>
                          </div>
                        </div>
                        <div className="seller-stats">
                          <div className="seller-score">
                            <div className="number title">
                              {val?.post?.length}
                            </div>
                            <div className="label">Total Items</div>
                          </div>
                          <div className="seller-score">
                            <div className="number title">
                              {val?.totalSalesAmount}
                            </div>
                            <div className="label">Total Likes</div>
                          </div>
                          {/* <div className="seller-score">
                            <div className="number title">4.9 / 5</div>
                            <div className="label">Avg.Rating</div>
                          </div> */}
                        </div>
                      </div>
                      <div className="seller-products">
                        <div className="products-previews">
                          {val?.post.length >= 1 && (
                            <div
                              className="preview-box"
                              onClick={() => {
                                setProductId(val?.post[0]?._id);
                                setShow(true);
                              }}
                            >
                              <Link to="">
                                <img
                                  src={Bucket + val?.post[0]?.thumbnail}
                                  alt=""
                                  className="h-100"
                                />
                              </Link>
                            </div>
                          )}
                          {val?.post.length >= 2 && (
                            <div
                              className="preview-box"
                              onClick={() => {
                                setProductId(val?.post[1]?._id);
                                setShow(true);
                              }}
                            >
                              <Link to="">
                                <img
                                  src={Bucket + val?.post[1]?.thumbnail}
                                  alt=""
                                  className="h-100"
                                />
                              </Link>
                            </div>
                          )}
                          {val?.post.length >= 3 && (
                            <div
                              className="preview-box"
                              onClick={() => {
                                setProductId(val?.post[3]?._id);
                                setShow(true);
                              }}
                            >
                              <Link to="">
                                <img
                                  src={Bucket + val?.post[2]?.thumbnail}
                                  alt=""
                                  className="h-100"
                                />
                              </Link>
                            </div>
                          )}
                          {val?.post.length >= 4 && (
                            <div
                              className="preview-box"
                              onClick={() => {
                                setProductId(val?.post[3]?._id);
                                setShow(true);
                              }}
                            >
                              <Link to="">
                                <img
                                  src={Bucket + val?.post[3]?.thumbnail}
                                  alt=""
                                  className="h-100"
                                />
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="more-link">
                          <Link
                            to={`/public/${val?._id}/profile`}
                            target="_blank"
                          >
                            <svg className="crumina-icon">
                              <BsArrowRight />
                            </svg>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

            {/* <SwiperSlide>
              <div
                className="swiper-slide swiper-slide-duplicate swiper-slide-duplicate-active"
                data-swiper-slide-index="0"
                role="group"
                aria-label="1 / 2"
              >
                <div className="seller-card">
                  <div className="about-seller">
                    <div className="seller-info">
                      <div className="avatar box-64">
                        <Link to="">
                          <img src={'/Image/avatar.png'} alt="avatar" />
                        </Link>
                        <span className="verified">
                          <svg className="crumina-icon">
                            <HiCheck />
                          </svg>
                        </span>
                      </div>
                      <div className="seller-meta">
                        <div className="title">
                          {navData?.parsedLoginData?.name}
                        </div>
                        <div className="meta">
                          @{navData?.parsedLoginData?.username}
                        </div>
                      </div>
                    </div>
                    <div className="seller-stats">
                      <div className="seller-score">
                        <div className="number title">179</div>
                        <div className="label">Total Items</div>
                      </div>
                      <div className="seller-score">
                        <div className="number title">13.5K</div>
                        <div className="label">Total Sales</div>
                      </div>
                      <div className="seller-score">
                        <div className="number title">4.8 / 5</div>
                        <div className="label">Avg.Rating</div>
                      </div>
                    </div>
                  </div>
                  <div className="seller-products">
                    <div className="products-previews">
                      <div className="preview-box">
                        <Link to="">
                          <img src={'/Image/project-thumb-41.png'} alt="" />
                        </Link>
                      </div>
                      <div className="preview-box">
                        <Link to="">
                          <img src={'/Image/project-thumb-42.png'} alt="" />
                        </Link>
                      </div>
                      <div className="preview-box">
                        <Link to="">
                          <img src={'/Image/project-thumb-43.png'} alt="" />
                        </Link>
                      </div>
                      <div className="preview-box">
                        <Link to="">
                          <img src={'/Image/project-thumb-44.png'} alt="" />
                        </Link>
                      </div>
                    </div>
                    <div className="more-link">
                      <Link to="">
                        <svg className="crumina-icon">
                          <BiDotsHorizontalRounded />
                        </svg>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide> */}
          </Swiper>
        )}
        <Dialog
          fullWidth={true}
          maxWidth="xl"
          open={show}
          onClose={() => {
            setShow(false);
            setProductId('');
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className="curveImage" id="style-1">
            <Row style={{ direction: 'rtl' }} className="position-relative">
              <div
                className="cursor-pointer crossImage"
                onClick={() => {
                  setShow(false);
                  setProductId('');
                }}
              >
                <IoCloseOutline size={24} />
              </div>
            </Row>
            <ProductDetailsModal
              productId={productId}
              // fetchUserPostLike={fetchUserPostLike}
              search=""
              setshow={() => setShow(false)}
            />
          </DialogContent>
        </Dialog>

        <span
          className="swiper-notification"
          aria-live="assertive"
          aria-atomic="true"
        ></span>
      </div>
    </div>
  );
};

export default HtmlTwoSlider;
