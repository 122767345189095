import React from 'react';
import { AiOutlineMinus } from 'react-icons/ai';

function Creator() {
  return (
    <div>
      <div className="become-creator-bc-box container-1300 section-padding-medium">
        <div className="bc-info">
          <div className="bc-heading">
            <div className="bc-heading-subtitle">JOIN OUR COMMUNITY!</div>
            <div className="bc-heading-title">
              Become a <span className="gradient-text">Creator</span>
            </div>
          </div>
          <div className="bc-description">
            Join thousands of other creators and start earning with your art,
            artworks, videos or art creations! The process takes only a few
            seconds, just fill the form and we’ll contact you
          </div>
          <div className="bc-button">
            <a className="btn btn-wide btn-dark" href="#application-form">
              Fill the Form
            </a>
          </div>
        </div>
        <div className="bc-image">
          <img src="/Image/become-creator.png" alt="" />
        </div>
      </div>
      <div className="container-1300 section-padding-medium">
        <h3 className="text-center">
          Benefits of <span className="gradient-text"> contributors </span>
        </h3>
        <div className="row mt-5">
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">Get appreciated by millions</div>
              <div className="item-description">
                Make your creative resources discoverable for others and get
                your resources appreciated by millions of creators.{' '}
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">Turn your passion into money</div>
              <div className="item-description">
                We offer you an opportunity to make money by selling your work.
                Get paid for every download your resources get. Fast payout at
                any time!{' '}
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">Personalised tips and support</div>
              <div className="item-description">
                Get personalised tips from our experts before publishing your
                resource to Unicorn UI. That will help you become a pro in your
                industry.{' '}
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">Stand out from the community</div>
              <div className="item-description">
                Get the chance to be an inspiration for someone by publishing
                your creative assets and helping others to create designs like
                you.{' '}
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">Level up your creativity</div>
              <div className="item-description">
                Build a network with other creators and artists on our platform
                and level up your creativity day by day.{' '}
              </div>
            </div>
          </div>
          <div className="col-md-4 px-3 mt-3">
            <div className="info-item">
              <div className="title h6">No spam, no offensive</div>
              <div className="item-description">
                Sell your resources with us, we promise there won't any spam or
                offensive. Just create, submit, and earn.{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container lock-in">
        <div className="border border-1 rounded-4 container">
          <h5 className="text-center mt-2">
            No Exclusivity <span className="gradient-text">Lock-in</span>{' '}
          </h5>
          <div className="text-center mb-4">
            We believe in your work, your rules. Feel free to sell your work on
            multiple marketplaces, or through your own site to get your work
            sold. We ensure that we’ll do everything to make your resource
            discoverable by more users and maximise your revenue.
          </div>
        </div>
      </div>
      <div className="main">
        <div className="primary-content-area section-medium content-padding">
          <div className="page-title text-center">
            <h3>
              Frequently <span className="gradient-text">Asked Questions</span>
            </h3>
          </div>
          <div className="accordion" id="accordionPanelsStayOpenExample">
            <div className=" border border-1 rounded-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingOne">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseOne"
                  aria-expanded="true"
                  aria-controls="panelsStayOpen-collapseOne"
                >
                  What can I sell on Unicorn UI?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseOne"
                className="accordion-collapse collapse show"
                aria-labelledby="panelsStayOpen-headingOne"
              >
                <div className="accordion-body">
                  You can sell UI kits for websites and applications, vectors,
                  illustrations, icon sets, mockups, and templates online. Every
                  day thousands of users download stock content from our
                  platform which means we're the best place to sell your
                  resources.
                </div>
              </div>
            </div>
            <div className=" mt-4 border border-1 rounded-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseTwo"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseTwo"
                >
                  How do I become a contributor on Unicorn UI?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingTwo"
              >
                <div className="accordion-body">
                  Register on our portal. Then, follow the application link sent
                  via email to upload your best creative resources, agree to our
                  contributor agreement, and start selling.
                </div>
              </div>
            </div>
            <div className="border border-1 rounded-4 mt-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingThree">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseThree"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseThree"
                >
                  Who is eligible to apply?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingThree"
              >
                <div className="accordion-body">
                  Everyone who has creative resources to share! Whether you are
                  an enthusiast or professional, you are welcome to apply to
                  become a contributor.
                </div>
              </div>
            </div>
            <div className="border border-1 rounded-4 mt-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingFour">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFour"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFour"
                >
                  Can I share my creative resources elsewhere?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFour"
              >
                <div className="accordion-body">
                  Yes! Our contributor program is non-exclusivity, so you can
                  continue sharing your creative resources on other platforms.
                </div>
              </div>
            </div>
            <div className="border border-1 rounded-4 mt-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingFive">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseFive"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseFive"
                >
                  How do I earn money?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseFive"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingFive"
              >
                <div className="accordion-body">
                  <div>
                    Earning money depends on price of your resource and how much
                    download you get on each asset. Currently, we’re offering a
                    pay-per-download program where you'll get money per
                    download.
                  </div>
                  <div>
                    We keep a 40% commission and give 60% to you on each sale
                    you get.
                  </div>
                </div>
              </div>
            </div>
            <div className="border border-1 rounded-4 mt-4">
              <h2 className="accordion-header" id="panelsStayOpen-headingSix">
                <button
                  className="accordion-button collapsed rounded-4"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#panelsStayOpen-collapseSix"
                  aria-expanded="false"
                  aria-controls="panelsStayOpen-collapseSix"
                >
                  Do you have any more question?
                </button>
              </h2>
              <div
                id="panelsStayOpen-collapseSix"
                className="accordion-collapse collapse"
                aria-labelledby="panelsStayOpen-headingSix"
              >
                <div className="accordion-body">
                  If you want to ask anything regarding contribution programme,
                  feel free to email at{' '}
                  <span className="fw_800">unicornuiofficial@gmail.com.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Creator;
